import React from 'react';
import {UseAddOrRemoveFav} from '@/hooks';
import {CustomButton, LoadingView} from '../common';
import Image from 'next/image';
import {
  capitalizeEachWord,
  convertEnglishNumbersToArabic,
  renderStars,
} from '@/common';
import {useLocale, useTranslations} from 'next-intl';
import {GoHeartFill} from 'react-icons/go';
import {useRouter} from '@/hooks';
import {useMemo} from 'react';

const FavoritesTab = () => {
  const locale = useLocale();
  const t = useTranslations();
  const router = useRouter();
  const {applyOrRemoveFavItem, localFavData, isFavProductsAreLoading, store} =
    UseAddOrRemoveFav();
  const handleRemoveFromFav = product => {
    applyOrRemoveFavItem(product);
  };

  const handleProductNavigation = (taxonName, product) => {
    if (product?.inStock) {
      router.push(
        `/${taxonName.toLowerCase().replace(/ /g, '-')}/${product.slug}/${product.id}?sid=${store?.id}`,
      );
    }
  };

  if (!localFavData || localFavData.length === 0) {
    return (
      <div className="flex h-[430px] flex-col items-center justify-center gap-4">
        <GoHeartFill className="text-[48px] font-bold text-pink" />
        <p className="text-center font-secondary text-[20px] text-persianIndigo">
          {t('no_favorite_products')}
        </p>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 xl:grid-cols-4">
      {localFavData.map(productWrapper => (
        <FavoriteProductCard
          key={productWrapper.product.id}
          product={productWrapper.product}
          locale={locale}
          t={t}
          onRemove={() => handleRemoveFromFav(productWrapper)}
          onNavigate={handleProductNavigation}
        />
      ))}
    </div>
  );
};

const FavoriteProductCard = ({product, locale, t, onRemove, onNavigate}) => {
  const productImage = product?.images?.[0]?.attributes?.original_url;
  const productName = product?.name;
  const taxonName = product?.taxonInfo?.[0]?.attributes?.name;
  const outOfStockProduct = useMemo(
    () => !product.inStock || !product.inBranchStock,
    [product],
  );

  return (
    <div className="mb-8 flex flex-col justify-between">
      <div className="relative">
        <div className="absolute top-[10px] sm:top-[16px] ltr:left-[10px] ltr:sm:left-[16px] rtl:right-[10px] rtl:sm:right-[16px]">
          <CustomButton
            iconOnly
            onClickHandling={onRemove}
            btnStyles="bg-white !p-[4px] !rounded-sm cursor-pointer !h-[30px] !w-[30px] !min-w-[30px]"
            value={<GoHeartFill className="text-[22px] font-bold text-pink" />}
          />
        </div>
        <Image
          src={productImage}
          alt={productName}
          onClick={() => onNavigate(taxonName, product)}
          width={600}
          height={400}
          className="w-full cursor-pointer object-fill sm:h-full"
        />
        {outOfStockProduct && (
          <div className="absolute bottom-0 w-full cursor-pointer">
            <p className="relative bg-white bg-opacity-50 py-5 text-center font-main text-[16px] font-thin text-persianIndigo">
              {t('out_of_stock')}
            </p>
          </div>
        )}
      </div>
      <div className="mt-2">
        <div className="flex items-center justify-between">
          <p className="font-main text-[12px] font-medium text-persianIndigo xl:text-[1vw]">
            {convertEnglishNumbersToArabic(Number(product.price), locale)}{' '}
            {t('sar')}
          </p>
          {product.totalRating > 0 && (
            <div className="flex items-center text-[12px] sm:text-[16px]">
              {renderStars(product.totalRating, 0, 0, 2)}
              <span
                className={`${
                  locale === 'ar' ? 'mr-2' : 'ml-2'
                } mt-1 font-secondary text-persianIndigo`}>
                {convertEnglishNumbersToArabic(product.totalRating, locale)}
              </span>
            </div>
          )}
        </div>
        <h3 className="font-secondary text-[12px] font-normal text-persianIndigo sm:text-[12px] xl:text-[0.8vw]">
          {capitalizeEachWord(productName)}
        </h3>
      </div>
    </div>
  );
};

export default FavoritesTab;
