'use client';

import React, {useState} from 'react';
import {CustomButton} from '../common';
import {
  ModalFooter,
  ModalContent,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
} from '@heroui/react';
import {FaRegEye, FaRegEyeSlash} from 'react-icons/fa';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {rules, showErrorToast} from '@/common';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslations} from 'next-intl';
import {getIsResetPasswordModalOpen} from '@/selectors';
import {closeResetPasswordModal, openResetPasswordModal} from '@/slices';
import {useForgetPasswordMutation} from '@/services';

const ResetPasswordModal = () => {
  const dispatch = useDispatch();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);

  const [isPasswordConfirmationVisible, setIsPasswordConfirmationVisible] =
    useState(false);
  const isResetPasswordModalOpen = useSelector(getIsResetPasswordModalOpen);
  const t = useTranslations();
  const [forgetPassword] = useForgetPasswordMutation();

  const validationSchema = Yup.object({
    password: rules('password', null, t),
    newPassword: rules('newPassword', null, t),
    passwordConfirmation: rules('newPasswordConfirmation', null, t),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      newPassword: '',
      passwordConfirmation: '',
    },
    validationSchema,
    onSubmit: async values => {
      const response = await forgetPassword({
        current_password: values.password,
        password: values.newPassword,
        password_confirmation: values.passwordConfirmation,
      });
      if (response.error) {
        showErrorToast(response.error.errors[0]);
      } else {
        onClosingResetPasswordUpdateModal();
      }
    },
  });

  const togglePasswordVisibility = () =>
    setIsPasswordVisible(!isPasswordVisible);
  const toggleNewPasswordVisibility = () =>
    setIsNewPasswordVisible(!isNewPasswordVisible);
  const togglePasswordConfirmationVisibility = () =>
    setIsPasswordConfirmationVisible(!isPasswordConfirmationVisible);

  const onOpeningResetPasswordUpdateModal = () => {
    dispatch(openResetPasswordModal());
  };
  const onClosingResetPasswordUpdateModal = () => {
    dispatch(closeResetPasswordModal());
  };

  return (
    <>
      <div>
        <Modal
          isOpen={isResetPasswordModalOpen}
          size="2xl"
          className="mx-4 p-12"
          onOpenChange={onOpeningResetPasswordUpdateModal}
          onClose={onClosingResetPasswordUpdateModal}
          closeButton={
            <CustomButton
              btnType="tertiary"
              value={'X'}
              onClickHandling={onClosingResetPasswordUpdateModal}
            />
          }
          classNames={{
            closeButton:
              '!text-[22px] !text-persianIndigo hover:!bg-thistle font-main !p-0 !rounded-md mt-4',
            wrapper: 'items-center',
          }}>
          <ModalContent>
            {onClose => (
              <>
                <ModalHeader className="justify-center">
                  <span className="w-[18.25rem] border-b-4 border-persianIndigo pb-2 text-center font-main text-[16px] font-medium text-persianIndigo sm:text-[20px] xl:text-[26px]">
                    {t('password_manager')}
                  </span>
                </ModalHeader>
                <form onSubmit={formik.handleSubmit}>
                  <ModalBody className="!px-0">
                    <div className="flex w-full flex-col">
                      <div className="mb-3 font-secondary">
                        <Input
                          type={isPasswordVisible ? 'text' : 'password'}
                          variant="underlined"
                          name="password"
                          label={t('password')}
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          endContent={
                            <button
                              className="focus:outline-none"
                              type="button"
                              onClick={togglePasswordVisibility}>
                              {isPasswordVisible ? (
                                <div className="rounded-full bg-thistle p-1">
                                  <FaRegEyeSlash className="pointer-events-none text-2xl text-[16px] text-white" />
                                </div>
                              ) : (
                                <div className="rounded-full bg-thistle p-1">
                                  <FaRegEye className="pointer-events-none text-2xl text-[16px] text-white" />
                                </div>
                              )}
                            </button>
                          }
                          className="w-full text-[16px]"
                          classNames={{
                            inputWrapper:
                              '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !outline-0 after:bg-persianIndigo',
                            input: '!text-persianIndigo',
                            label: '!text-thistle',
                          }}
                        />
                        {formik.touched.password && formik.errors.password ? (
                          <div
                            className={`mt-2 font-secondary text-[12px] text-red-500`}>
                            {formik.errors.password}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-3 font-secondary">
                        <Input
                          type={isNewPasswordVisible ? 'text' : 'password'}
                          variant="underlined"
                          name="newPassword"
                          label={t('new_password')}
                          value={formik.values.newPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          endContent={
                            <button
                              className="focus:outline-none"
                              type="button"
                              onClick={toggleNewPasswordVisibility}>
                              {isNewPasswordVisible ? (
                                <div className="rounded-full bg-thistle p-1">
                                  <FaRegEyeSlash className="pointer-events-none text-2xl text-[16px] text-white" />
                                </div>
                              ) : (
                                <div className="rounded-full bg-thistle p-1">
                                  <FaRegEye className="pointer-events-none text-2xl text-[16px] text-white" />
                                </div>
                              )}
                            </button>
                          }
                          className="w-full text-[16px]"
                          classNames={{
                            inputWrapper:
                              '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !outline-0 after:bg-persianIndigo',
                            input: '!text-persianIndigo',
                            label: '!text-thistle',
                          }}
                        />
                        {formik.touched.newPassword &&
                        formik.errors.newPassword ? (
                          <div
                            className={`mt-2 font-secondary text-[12px] text-red-500`}>
                            {formik.errors.newPassword}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-3 w-full font-secondary">
                        <Input
                          label={t('password_confirmation')}
                          variant="underlined"
                          endContent={
                            <button
                              className="focus:outline-none"
                              type="button"
                              onClick={togglePasswordConfirmationVisibility}>
                              {isPasswordConfirmationVisible ? (
                                <div className="rounded-full bg-thistle p-1">
                                  <FaRegEyeSlash className="pointer-events-none text-2xl text-[16px] text-white" />
                                </div>
                              ) : (
                                <div className="rounded-full bg-thistle p-1">
                                  <FaRegEye className="pointer-events-none text-2xl text-[16px] text-white" />
                                </div>
                              )}
                            </button>
                          }
                          type={
                            isPasswordConfirmationVisible ? 'text' : 'password'
                          }
                          name="passwordConfirmation"
                          value={formik.values.passwordConfirmation}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          classNames={{
                            label: '!text-thistle',
                            inputWrapper:
                              '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !outline-0 after:bg-persianIndigo',
                            input: '!text-persianIndigo',
                          }}
                        />
                        {formik.touched.passwordConfirmation &&
                        formik.errors.passwordConfirmation ? (
                          <div
                            className={`mt-2 font-secondary text-[12px] text-red-500`}>
                            {formik.errors.passwordConfirmation}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </ModalBody>
                  <ModalFooter className="!px-0">
                    <CustomButton
                      btnActionType="submit"
                      btnStyles={'w-full'}
                      value={t('change')}
                      isDisabled={!formik.isValid || !formik.dirty}
                    />
                  </ModalFooter>
                </form>
              </>
            )}
          </ModalContent>
        </Modal>
      </div>
    </>
  );
};

export default ResetPasswordModal;
