'use client';

import React, {useState} from 'react';
import {
  Checkbox,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@heroui/react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useAddUserAddressMutation} from '@/services';
import {rules, showErrorToast} from '@/common';
import {CustomButton} from '../common';
import {phone_label} from '@/assets';
import Image from 'next/image';
import {MapView} from '../Receiver';
import {useLocale} from 'next-intl';

const AddAddressModal = ({isOpen, onClose, t}) => {
  const [addUserAddress] = useAddUserAddressMutation();
  const [formError, setFormError] = useState('');
  const [isDefaultAddress, setIsDefaultAddress] = useState(false);
  const locale = useLocale();

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: 'N/A',
      address1: '',
      country_iso: 'SA',
      phone: '',
      national_address: '',
      building_number: '',
      apartment_number: '',
      location_trademark: '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      firstname: rules('firstName', null, t),
      phone: rules('phoneNumber', null, t),
      national_address: rules('nationalAddress', null, t),
    }),
    onSubmit: async (values, {resetForm}) => {
      const response = await addUserAddress({
        body: {
          address: {
            firstname: values.firstname,
            lastname: values.lastname,
            address1: values.address1 || '',
            country_iso: values.country_iso,
            phone: values.phone,
            building_number: values.building_number || '',
            apartment_number: values.apartment_number || '',
            location_trademark: values.location_trademark || '',
            city: 'N/A',
            default: isDefaultAddress,
          },
        },
      });
      if (response?.error) {
        showErrorToast(response?.error.error);
      } else {
        resetForm();
        onClose();
      }
    },
  });

  const handleLocationSelected = () => {
    setFormError('');
  };

  return (
    <Modal
      isOpen={isOpen}
      size="2xl"
      className="h-[60vh] overflow-y-auto p-16 scrollbar-hide md:h-[90vh]"
      onClose={onClose}
      classNames={{
        closeButton:
          '!text-[22px] !text-persianIndigo hover:!bg-thistle font-main !p-0 !rounded-md mt-4 !hidden sm:block',
        wrapper: 'items-center',
      }}>
      <ModalContent>
        <>
          <ModalHeader className="justify-center">
            <span className="w-[16.25rem] border-b-4 border-thistle pb-[1.5rem] text-center font-main text-[26px] font-medium text-persianIndigo">
              {t('add_address')}
            </span>
          </ModalHeader>
          <form onSubmit={formik.handleSubmit}>
            <ModalBody>
              <div className="mb-9">
                <Input
                  type="text"
                  variant="underlined"
                  name="firstname"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstname}
                  placeholder={t('first_name')}
                  className="font-secondary text-[20px] font-normal text-persianIndigo"
                  classNames={{
                    label: '!text-thistle',
                    inputWrapper:
                      '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !outline-0 after:bg-persianIndigo',
                    input:
                      'placeholder:font-secondary placeholder:text-[16px] placeholder:font-normal placeholder:text-thistle !text-persianIndigo',
                  }}
                />
                {formik.touched.firstname && formik.errors.firstname ? (
                  <div className="mt-2 font-secondary text-[12px] text-red-500">
                    {formik.errors.firstname}
                  </div>
                ) : null}
              </div>

              <div className="mb-9 font-secondary text-persianIndigo">
                <Input
                  type="text"
                  variant="underlined"
                  name="phone"
                  startContent={
                    <div
                      className={`${locale === 'ar' ? 'ml-2' : 'mr-2'} flex w-[7.3rem] items-center gap-3 md:w-[6.3rem]`}>
                      <Image
                        width={35}
                        height={10}
                        src={phone_label}
                        alt="phone label"
                      />
                      <p className="text-[14px] text-persianIndigo">+966</p>
                    </div>
                  }
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  classNames={{
                    label: '!text-thistle',
                    inputWrapper:
                      '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !outline-0 after:bg-persianIndigo',
                    input:
                      'placeholder:font-secondary placeholder:text-[16px] placeholder:font-normal placeholder:text-thistle !text-persianIndigo',
                  }}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <div className="mt-2 font-secondary text-[12px] text-red-500">
                    {formik.errors.phone}
                  </div>
                ) : null}
              </div>

              <div className="flex flex-col gap-3">
                <span className="text-persianIndigo">
                  {t('pick_your_location')}
                </span>
                <MapView
                  selected={'pickLocation'}
                  formik={formik}
                  onLocationSelected={handleLocationSelected}
                />
              </div>
              {formik.values.address1 && (
                <div className="mb-4 font-secondary text-persianIndigo">
                  <span className="text-[16px] font-bold">
                    {t('selected_address')}:{' '}
                  </span>
                  <span className="text-[14px]">{formik.values.address1}</span>
                </div>
              )}
              {formError && (
                <div className="mb-9 font-secondary text-[12px] text-red-500">
                  {formError}
                </div>
              )}
              <div className="mb-9">
                <Input
                  type="text"
                  variant="underlined"
                  name="building_number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.building_number}
                  placeholder={t('building_number')}
                  className="font-secondary text-[20px] font-normal text-persianIndigo"
                  classNames={{
                    label: '!text-thistle',
                    inputWrapper:
                      '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !outline-0 after:bg-persianIndigo',
                    input:
                      'placeholder:font-secondary placeholder:text-[16px] placeholder:font-normal placeholder:text-thistle !text-persianIndigo',
                  }}
                />
              </div>

              <div className="mb-9">
                <Input
                  type="text"
                  variant="underlined"
                  name="apartment_number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.apartment_number}
                  placeholder={t('apt_number')}
                  className="font-secondary text-[20px] font-normal text-persianIndigo"
                  classNames={{
                    label: '!text-thistle',
                    inputWrapper:
                      '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !outline-0 after:bg-persianIndigo',
                    input:
                      'placeholder:font-secondary placeholder:text-[16px] placeholder:font-normal placeholder:text-thistle !text-persianIndigo',
                  }}
                />
              </div>

              <Checkbox
                radius="small"
                color="secondary"
                className="ms-[-2.2rem]"
                onValueChange={setIsDefaultAddress}>
                <span className="font-secondary text-[16px] font-normal text-persianIndigo">
                  {t('set_as_default_address')}
                </span>
              </Checkbox>
            </ModalBody>
            <ModalFooter>
              <CustomButton
                btnStyles="w-full"
                btnActionType="submit"
                value={t('add')}
                disabled={formik.isSubmitting}
                isLoading={formik.isSubmitting}
              />
            </ModalFooter>
          </form>
        </>
      </ModalContent>
    </Modal>
  );
};

export default AddAddressModal;
