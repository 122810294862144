'use client';

import {logo} from '@/assets';
import {
  capitalizeEachWord,
  convertEnglishNumbersToArabic,
  renderStars,
} from '@/common/helpers';
import {Pagination} from '@heroui/react';
import {useLocale} from 'next-intl';
import Image from 'next/image';
import Link from 'next/link';
import {useMemo} from 'react';
import {CustomButton, LoadingView} from '../common';
import {UseAddOrRemoveFav} from '@/hooks';
import {GoHeart, GoHeartFill} from 'react-icons/go';

const ProductList = ({
  products,
  category,
  t,
  isLoading,
  setCurrentPage,
  currentPage,
  isAllSection,
  storeId,
}) => {
  const locale = useLocale();

  const filteredProducts = useMemo(() => {
    return products?.data.filter(product => {
      const hasImages = product?.relationships.images.data.length > 0;
      const isInStock = product?.attributes.in_stock === true;

      return hasImages && isInStock;
    });
  }, [products?.data]);
  const getImageUrl = imageId => {
    const image = products?.included.find(img => img.id === imageId);
    return image ? image.attributes.original_url : null;
  };

  const {
    applyOrRemoveFavItem,
    localFavData,
    isFavProductsAreLoading,
    favToken,
  } = UseAddOrRemoveFav();

  const onHandlingFavoriteList = product => {
    const isProductInFavorites = localFavData?.find(
      item => item?.product.id === product?.id,
    );
    return isProductInFavorites;
  };

  const onHandlingFavActions = product => {
    applyOrRemoveFavItem(
      onHandlingFavoriteList(product),
      product?.relationships?.default_variant?.data?.id,
      product?.id,
    );
  };

  const getRelatedTaxon = taxonId => {
    const taxon = products?.included
      .filter(taxon => taxon.type === 'taxon')
      .find(taxon => taxon.id === taxonId);
    return taxon
      ? taxon.attributes.name.toLowerCase().replace(/ /g, '-')
      : null;
  };

  if (isLoading) {
    return <LoadingView />;
  } else {
    return (
      <div className={`w-full ltr:sm:!mr-0 rtl:sm:!mr-8`}>
        {filteredProducts?.length > 0 ? (
          <>
            <div
              className={`grid grid-cols-2 gap-x-4 px-6 sm:mt-0 sm:px-0 xl:grid-cols-4`}>
              {filteredProducts?.map((product, index) => {
                const imageUrl = getImageUrl(
                  product.relationships.images.data[0]?.id,
                );
                const taxonName = getRelatedTaxon(
                  product?.relationships.taxons.data[0]?.id,
                );
                return (
                  <div
                    key={index}
                    className="relative mb-4 flex flex-col justify-between sm:mb-8">
                    <Link
                      href={`/${locale}/${category || taxonName}/${product?.attributes?.slug}/${product.id}?sid=${storeId}`}>
                      <Image
                        src={`${imageUrl}`}
                        alt={product.attributes.name}
                        width={600}
                        height={400}
                        className="h-[21.45vh] w-full cursor-pointer rounded-[16px] object-fill sm:h-[39.5vh]"
                      />
                    </Link>
                    {favToken && (
                      <div className="absolute top-0 sm:top-[10px] ltr:right-[4px] ltr:sm:right-[10px] rtl:left-[4px] rtl:sm:left-[10px]">
                        <CustomButton
                          iconOnly
                          isLoading={isFavProductsAreLoading}
                          btnStyles="!bg-transparent cursor-pointer"
                          onClickHandling={() => onHandlingFavActions(product)}
                          value={
                            onHandlingFavoriteList(product) ? (
                              <GoHeartFill className="text-[22px] font-bold text-persianIndigo" />
                            ) : (
                              <GoHeart className="text-[22px] font-bold text-spanishViolet" />
                            )
                          }
                        />
                      </div>
                    )}
                    <div className="mt-2">
                      <div className="flex items-center justify-between">
                        <p className="font-main text-[12px] font-medium text-persianIndigo xl:text-[1vw]">
                          {convertEnglishNumbersToArabic(
                            Number(product.attributes.price),
                            locale,
                          )}{' '}
                          {t('sar')}
                        </p>
                        {product.attributes.total_rating > 0 && (
                          <div className="flex items-center text-[12px] sm:text-[16px]">
                            {renderStars(
                              product.attributes.total_rating,
                              0,
                              0,
                              2,
                            )}
                            <span
                              className={`${locale === 'ar' ? 'mr-2' : 'ml-2'} mt-1 font-secondary text-persianIndigo`}>
                              {convertEnglishNumbersToArabic(
                                product.attributes.total_rating,
                                locale,
                              )}
                            </span>
                          </div>
                        )}
                      </div>
                      <h3 className="font-secondary text-[12px] font-normal text-persianIndigo sm:text-[12px] xl:text-[0.8vw]">
                        {capitalizeEachWord(product.attributes.name)}
                      </h3>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex w-full justify-center">
              <Pagination
                total={products?.meta?.total_pages}
                initialPage={1}
                page={currentPage}
                onChange={page => setCurrentPage(page)}
                isCompact
                showControls={true}
                classNames={{
                  cursor: 'hidden',
                  wrapper: 'shadow-none text-persianIndigo',
                  item: 'hidden',
                  prev: 'bg-white shadow-none text-persianIndigo',
                  next: 'bg-white shadow-none text-persianIndigo',
                }}
              />
            </div>
          </>
        ) : (
          <div className="mt-[3.75rem] flex h-[75vh] flex-col items-center justify-start xl:mt-0 xl:justify-center">
            <Image src={logo} alt="no results" width={100} height={100} />
            <h2 className="mt-4 font-secondary text-lg sm:text-2xl">
              {t('no_results')}
            </h2>
            <p className="mt-2 w-[100%] text-center font-main text-[12px] sm:w-[50%] sm:text-lg">
              {t(
                'there_are_no_products_with_those_specific_filters_try_changing_your_filters_or_category',
              )}
            </p>
          </div>
        )}
      </div>
    );
  }
};

export default ProductList;
