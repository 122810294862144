'use client';
import React, {useState} from 'react';
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from '@heroui/react';
import {usePathname, useRouter} from '@/utils/navigation';
import {useLocale} from 'next-intl';
import {MdKeyboardArrowDown} from 'react-icons/md';

const LanguageDropDown = () => {
  const router = useRouter();
  const pathName = usePathname();
  const locale = useLocale();
  const [selectedKeys, setSelectedKeys] = useState([locale]);

  const selectedValue = selectedKeys[0] === 'ar' ? 'العربية' : 'English';

  const handleLanguageChange = keys => {
    if (selectedKeys[0] !== keys.currentKey) {
      setSelectedKeys(keys);
      router.replace(pathName, {locale: keys.currentKey});
    }
  };

  return (
    <Dropdown className="flex min-w-[130px]">
      <DropdownTrigger>
        <div className="flex min-w-[3.5rem] cursor-pointer justify-end px-0 font-secondary text-[12px] font-medium capitalize text-spanishViolet sm:text-[16px]">
          {selectedValue}
          <MdKeyboardArrowDown
            size={20}
            className="relative bottom-[3px] sm:static"
          />
        </div>
      </DropdownTrigger>
      <DropdownMenu
        variant="flat"
        disallowEmptySelection
        selectionMode="single"
        selectedKeys={selectedKeys}
        onSelectionChange={handleLanguageChange}>
        <DropdownItem
          key="en"
          className="!text-persianIndigo focus:text-persianIndigo"
          classNames={{
            title:
              'data-[selectable=true]:focus:text-persianIndigo:focus[data-selectable=true]',
          }}>
          English
        </DropdownItem>
        <DropdownItem key="ar" className="!text-persianIndigo">
          العربية
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default LanguageDropDown;
