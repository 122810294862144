'use client';

import {getDeliveryMethodId} from '@/selectors';
import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslations} from 'next-intl';
import {DeliveryMapModal, LanguageDropDown} from '.';
import Image from 'next/image';
import {phone, saudiFlagRounded, truck} from '@/assets';
import {openDeliveryMapModal} from '@/slices';
import {showErrorToast} from '@/common';
import {PiMapPin} from 'react-icons/pi';
import {useMediaQuery} from 'react-responsive';
import {MdKeyboardArrowDown} from 'react-icons/md';

const isMobileDevice = () => {
  if (typeof navigator !== 'undefined') {
    return /Mobi|Android|iPhone|iPad|iPod/.test(navigator.userAgent);
  }
  return false;
};

const UpperHeader = ({nearestStore}) => {
  const deliveryMethodId = useSelector(getDeliveryMethodId);
  const t = useTranslations();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({query: '(max-width: 640px)'});

  const handleStoreClick = () => {
    dispatch(openDeliveryMapModal());
  };

  const handleCallClick = useCallback(() => {
    if (isMobileDevice()) {
      window.location.href = 'tel:0536679457';
    } else {
      showErrorToast(t('your_device_does_not_support_calling'));
    }
  }, []);
  return (
    <>
      <div className="flex items-center justify-between px-[1rem] py-4 shadow-md sm:px-[9vw]">
        <div className="flex w-[50%] grow items-center gap-2 sm:grow-[0] sm:gap-10">
          <div className="flex items-center gap-2">
            <Image src={truck} width={32} height={32} alt="truck" />
            <p className="font-secondary text-[12px] font-medium text-spanishViolet sm:text-[16px]">
              {t('free_delivery')}
            </p>
          </div>
          {!isMobile && (
            <div
              className="flex cursor-pointer items-center gap-2"
              onClick={handleCallClick}>
              <Image src={phone} width={25} height={25} alt="phone" />
              <div>
                <p className="font-secondary text-[12px] font-normal text-spanishViolet">
                  {t('hot_line')}
                </p>
                <p
                  className="font-secondary text-[12px] font-medium text-spanishViolet sm:text-[16px]"
                  dir="ltr">
                  053 667 9457
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="flex w-[50%] grow items-center justify-end gap-2 sm:grow-[0] sm:gap-10">
          <div
            className="flex cursor-pointer items-center gap-2"
            onClick={handleStoreClick}>
            {isMobile ? (
              <Image
                src={saudiFlagRounded}
                height={15}
                alt="rounded saudi flag"
                className="relative bottom-[3px]"
                width={15}
              />
            ) : (
              <PiMapPin color="#402D7E" size={30} />
            )}
            <div>
              <p className="hidden font-secondary text-[10px] font-normal text-spanishViolet sm:block sm:text-[12px]">
                {t('delivery_to')}
              </p>
              <div className="flex">
                <p className="font-secondary text-[12px] font-medium text-spanishViolet sm:text-[16px]">
                  {nearestStore?.deliveryZone?.name || t('store_name')}
                </p>
                {isMobile && (
                  <MdKeyboardArrowDown className="relative bottom-[3px] text-[20px] text-persianIndigo" />
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <LanguageDropDown />
          </div>
        </div>
      </div>
      <DeliveryMapModal shoppingMethodId={deliveryMethodId} />
    </>
  );
};

export default UpperHeader;
