'use client';
import {categoryImage} from '@/assets';
import {getNearestStoreData} from '@/selectors';
import {useGetAnnouncementsQuery} from '@/services';
import {useRouter} from '@/hooks';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import {useLocale} from 'next-intl';
import Image from 'next/image';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useMediaQuery} from 'react-responsive';

const HomeHeroView = () => {
  const locale = useLocale();
  const router = useRouter();
  const [emblaRef, emblaApi] = useEmblaCarousel({loop: true}, [
    Autoplay({
      delay: 5000,
      playOnInit: true,
    }),
  ]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const store = useSelector(getNearestStoreData);
  const {
    currentData: announcements,
    isFetching,
    isUninitialized,
  } = useGetAnnouncementsQuery({
    locale,
  });
  const isMobile = useMediaQuery({query: '(max-width: 640px)'});

  useEffect(() => {
    if (!emblaApi) return;

    const onSelect = () => {
      setSelectedIndex(emblaApi.selectedScrollSnap());
    };

    emblaApi.on('select', onSelect);
    onSelect();
  }, [emblaApi, selectedIndex, announcements]);

  const onHandlingAnnouncementNavigation = async item => {
    try {
      if (item?.sourceId) {
        if (item?.sourceType?.includes('Product')) {
          router.push(
            `/${item?.permalink}/${item?.announcementSlug}/${item?.sourceId}?sid=${store?.id}`,
          );
        } else {
          router.push(
            `/category/${item?.announcementSlug.replace('/all', '')}/${item?.sourceId}`,
          );
        }
      } else {
        return null;
      }
    } catch (error) {
      console.error('Navigation error:', error);
    }
  };

  const gettingImageSrc = useCallback(
    item => {
      if (isMobile && item?.phoneImageUrl) {
        return item?.phoneImageUrl;
      } else {
        return item?.webImageUrl || item?.phoneImageUrl || categoryImage;
      }
    },
    [isMobile],
  );

  const showPlaceholder = useMemo(
    () => isFetching || isUninitialized,
    [isFetching, isUninitialized],
  );

  return (
    <div className="relative flex w-full flex-col justify-end overflow-hidden">
      <div
        ref={emblaRef}
        className={showPlaceholder ? 'h-[500px] sm:h-[675px]' : ''}>
        <div
          className={`${
            locale === 'ar' ? 'flex-row-reverse' : 'flex-row'
          } flex`}>
          {announcements?.map((item, index) => (
            <div className="flex-[0_0_100%] cursor-pointer" key={index}>
              <div className="flex h-[500px] items-center justify-center sm:h-[675px]">
                {(item?.webImageUrl || item?.phoneImageUrl) && (
                  <Image
                    key={index}
                    src={gettingImageSrc(item)}
                    width={0}
                    height={0}
                    sizes="100vw"
                    style={{width: '100%', height: '100%'}}
                    alt={'Announcement ' + index + 1}
                    onClick={() => onHandlingAnnouncementNavigation(item)}
                    decoding="sync"
                    unoptimized
                    priority={index === 0}
                    loading={index === 0 ? 'eager' : 'lazy'}
                    loader={({src}) => src}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="absolute flex w-full items-end justify-center sm:justify-end sm:px-6 xl:px-[3.75rem] xl:pb-[1rem]">
        <div className={`mb-8 flex items-center justify-end sm:mb-[4.25rem]`}>
          {announcements?.length > 1 &&
            announcements?.map((_, index) => (
              <button
                key={index}
                onClick={() => emblaApi.scrollTo(index)}
                className={`h-1 w-[3.5rem] sm:w-24 ${
                  index === selectedIndex
                    ? 'bg-persianIndigo sm:bg-royalPurple'
                    : 'bg-darkWhite'
                } transition-colors`}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default HomeHeroView;
