import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalBody,
} from '@heroui/react';
import React from 'react';
import {CustomButton} from '../common';
import {useDispatch, useSelector} from 'react-redux';
import {getDeleteUserAddressModal} from '@/selectors';
import {
  closeDeleteUserAddressModal,
  openDeleteUserAddressModal,
} from '@/slices';
import {useDeleteUserAddressMutation} from '@/services';
import {useTranslations} from 'next-intl';
import {showErrorToast} from '@/common';

const DeleteUserAddressModal = ({address}) => {
  const t = useTranslations();
  const isDeleteUserAddressModalOpen = useSelector(getDeleteUserAddressModal);
  const dispatch = useDispatch();
  const [deleteUserAddress, {isLoading: isDeleteingUserAddressIsLoading}] =
    useDeleteUserAddressMutation();
  const onOpenDeleteUserAddressModal = () => {
    dispatch(openDeleteUserAddressModal());
  };
  const onCloseDeleteUserAddressModal = () => {
    dispatch(closeDeleteUserAddressModal());
  };

  const onHandlingDeletingAddress = async () => {
    const response = await deleteUserAddress(address?.id);
    if (response?.error) {
      showErrorToast(response?.error.error);
    } else {
      onCloseDeleteUserAddressModal();
    }
  };

  return (
    <>
      <Modal
        size="2xl"
        className="p-12"
        isOpen={isDeleteUserAddressModalOpen}
        onOpenChange={onOpenDeleteUserAddressModal}
        onClose={onCloseDeleteUserAddressModal}
        classNames={{
          closeButton:
            '!text-[22px] !text-persianIndigo hover:!bg-thistle font-main !p-0 !rounded-md mt-4 !hidden sm:block',
          wrapper: 'items-center',
        }}>
        <ModalContent>
          {onClose => (
            <>
              <ModalHeader className="flex justify-center">
                <span className="w-[260px] border-b-4 border-thistle pb-[10px] text-center font-main text-[26px] font-medium text-persianIndigo">
                  {t('delete_address')}
                </span>
              </ModalHeader>
              <ModalBody>
                <p className="text-center font-secondary text-persianIndigo">
                  {t('are_you_sure_you_want_to_delete_this_address')}
                </p>
              </ModalBody>
              <ModalFooter>
                <div className="flex w-full justify-between gap-6">
                  <CustomButton
                    btnStyles="w-full"
                    btnType="secondary"
                    value={t('cancel')}
                    onClickHandling={onCloseDeleteUserAddressModal}
                  />
                  <CustomButton
                    btnStyles="w-full"
                    isLoading={isDeleteingUserAddressIsLoading}
                    value={t('delete')}
                    onClickHandling={onHandlingDeletingAddress}
                  />
                </div>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteUserAddressModal;
