'use client';
import {Divider, Link} from '@heroui/react';
import React, {useCallback} from 'react';
import {FaInstagram} from 'react-icons/fa6';
import {FaFacebookF} from 'react-icons/fa';
import {useLocale, useTranslations} from 'next-intl';
import {useRouter} from '@/hooks';
import {CustomButton, FooterFlowers} from '../common';
import {BsSnapchat, BsTwitterX} from 'react-icons/bs';
import {IoLogoTiktok} from 'react-icons/io5';
import {MdOutlineMailOutline} from 'react-icons/md';
import {IoIosArrowUp} from 'react-icons/io';

const Footer = () => {
  const t = useTranslations();
  const locale = useLocale();
  const router = useRouter();

  const onHandlingNavigation = useCallback(
    path => {
      router.push(path);
    },
    [router],
  );

  const scrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  return (
    <div className=":mt-[2.188rem] footer relative bg-persianIndigo">
      <div className="grid grid-cols-1 gap-4 overflow-hidden px-6 py-[1.5rem] sm:mx-[100px] sm:grid-cols-2 sm:gap-8 sm:py-[2.5rem] xl:grid-cols-3">
        <div className="order-1 sm:order-1 xl:order-3">
          <h3 className="mb-4 font-main text-[16px] text-[18px] font-medium text-white xl:text-[18px]">
            {t('popular_shortcuts')}
          </h3>
          <ul className="grid grid-cols-2 font-secondary text-white sm:grid-cols-1">
            <li
              onClick={() => onHandlingNavigation('/about-us')}
              className="mb-2 cursor-pointer text-[12px] font-normal hover:underline xl:text-[14px]">
              {t('about_us')}
            </li>
            <li
              onClick={() => onHandlingNavigation('/privacy-and-policy')}
              className="mb-2 cursor-pointer text-[12px] font-normal hover:underline xl:text-[14px]">
              {t('shipping_and_delivery')}
            </li>
            <li
              className="mb-2 cursor-pointer text-[12px] font-normal hover:underline xl:text-[14px]"
              onClick={() => onHandlingNavigation('/contact')}>
              {t('contact_us')}
            </li>
            <li
              onClick={() => onHandlingNavigation('/faq')}
              className="mb-2 cursor-pointer text-[12px] font-normal hover:underline xl:text-[14px]">
              {t('faqs')}
            </li>
            <li
              onClick={() => onHandlingNavigation('/faq')}
              className="mb-2 cursor-pointer text-[12px] font-normal hover:underline xl:text-[14px]">
              {t('terms_and_conditions')}
            </li>
            <li
              onClick={() => onHandlingNavigation('/privacy-and-policy')}
              className="mb-2 cursor-pointer text-[12px] font-normal hover:underline xl:text-[14px]">
              {t('privacy_policy')}
            </li>
            <li
              onClick={() => onHandlingNavigation('/event-planning')}
              className="mb-2 cursor-pointer text-[12px] font-normal hover:underline xl:text-[14px]">
              {t('events_planning')}
            </li>
          </ul>
        </div>
        <div className="order-2 sm:order-3 sm:col-span-2 xl:col-span-1">
          <h3 className="mb-4 font-main text-[18px] font-normal text-white sm:text-[16px] xl:text-[18px]">
            {t('connect_with_us_on_social_medias')}
          </h3>
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="relative z-[10] flex flex-wrap gap-4">
              <CustomButton
                iconOnly
                aria-label="facebook icon"
                as={Link}
                href="https://www.facebook.com/SpringRose.sa"
                isExternal
                btnStyles=" h-[2rem] w-[2rem] sm:h-[2.25rem] sm:w-[2.25rem]  min-w-8 sm:!min-w-9 bg-white sm:p-3 xl:p-2"
                value={
                  <FaFacebookF className="text-[16px] text-persianIndigo sm:text-[24px]" />
                }
              />
              <CustomButton
                iconOnly
                aria-label="instagram icon"
                href="https://www.instagram.com/springroseshop/"
                as={Link}
                isExternal
                btnStyles=" h-[2rem] w-[2rem] sm:h-[2.25rem] sm:w-[2.25rem]  min-w-8 sm:!min-w-9 bg-white sm:p-3 xl:p-2"
                value={
                  <FaInstagram className="text-[16px] text-persianIndigo sm:text-[24px]" />
                }
              />
              <CustomButton
                as={Link}
                aria-label="snapChat icon"
                isExternal
                href="https://www.snapchat.com/add/springroseshop"
                iconOnly
                btnStyles=" h-[2rem] w-[2rem] sm:h-[2.25rem] sm:w-[2.25rem]  min-w-8 sm:!min-w-9 bg-white sm:p-3 xl:p-2"
                value={
                  <BsSnapchat className="text-[16px] text-persianIndigo sm:text-[24px]" />
                }
              />
              <CustomButton
                iconOnly
                aria-label="tiktok icon"
                href="https://www.tiktok.com/@springrose_shop"
                as={Link}
                isExternal
                btnStyles=" h-[2rem] w-[2rem] sm:h-[2.25rem] sm:w-[2.25rem]  min-w-8 sm:!min-w-9 bg-white sm:p-3 xl:p-2"
                value={
                  <IoLogoTiktok className="text-[16px] text-persianIndigo sm:text-[24px]" />
                }
              />
              <CustomButton
                href="https://x.com/SpringRoseShop"
                aria-label="x icon"
                isExternal
                as={Link}
                iconOnly
                btnStyles=" h-[2rem] w-[2rem] sm:h-[2.25rem] sm:w-[2.25rem]  min-w-8 sm:!min-w-9 bg-white sm:p-3 xl:p-2"
                value={
                  <BsTwitterX className="text-[16px] text-persianIndigo sm:text-[24px]" />
                }
              />
              <CustomButton
                as={Link}
                aria-label="SpringRose Mail"
                href="mailto:Info@springrose.com.sa"
                iconOnly
                btnStyles=" h-[2rem] w-[2rem] sm:h-[2.25rem] sm:w-[2.25rem]  min-w-8 sm:!min-w-9 bg-white sm:p-3 xl:p-2"
                value={
                  <MdOutlineMailOutline className="text-[16px] text-persianIndigo sm:text-[24px]" />
                }
              />
            </div>
          </div>
        </div>
        <div
          className={`${locale === 'ar' ? 'sm:mr-[1.25rem] xl:mr-[7.5rem]' : 'sm:ml-[1.25rem] xl:ml-[7.5rem]'} relative z-[5] order-3 flex flex-col items-start sm:order-2 xl:order-3`}>
          <h3
            className={`${locale === 'ar' ? 'ml-[1.563rem]' : 'mr-[1.563rem]'} mb-4 font-main text-[18px] font-medium text-white sm:text-[16px] xl:text-[18px]`}>
            {t('do_you_have_a_question')}
          </h3>
          <p className="mb-4 max-w-[21.625rem] font-secondary text-[14px] text-white">
            {t(
              'do_you_have_any_enquiries_send_a_message_to_our_customer_support_team_and_we_will_reply_soon',
            )}
          </p>
          <CustomButton
            btnType="secondary"
            btnStyles={'px-[3.75rem] py-5 text-white border-white'}
            value={t('ask_away')}
            onClick={() => onHandlingNavigation('/contact')}
          />
        </div>
      </div>
      <CustomButton
        aria-label="navigate to top"
        iconOnly
        onClickHandling={scrollToTop}
        btnStyles=" h-[2rem] w-[2rem] mt-4 z-[100]  !min-w-8 absolute bottom-[64px] ltr:right-[20px] ltr:sm:right-[52px] rtl:left-[20px] rtl:sm:left-[52px] bg-white sm:p-3 xl:p-[0.25rem]"
        value={
          <IoIosArrowUp className="text-[30px] text-persianIndigo sm:text-[24px]" />
        }
      />
      <Divider className="mx-auto w-[93%] bg-white" orientation="horizontal" />
      <div className="mx-[55px] py-4">
        <p className="text-center font-main text-[12px] font-normal text-white sm:text-left sm:text-[12px] xl:text-[14px]">
          {t('copyright_2024_all_rights_reserved')}
        </p>
      </div>
    </div>
  );
};

export default Footer;
