'use client';

import React, {useState} from 'react';
import {Modal, ModalContent, ModalBody} from '@heroui/react';
import {QuickViewCarousal, QuickViewData} from './QuickViewDetails';

const ProductQuickView = ({isOpen, onOpenChange, selectedProduct}) => {
  const [selectedVariant, setSelectedVariant] = useState(
    selectedProduct?.variants?.filter(v => v.inStock)?.[0],
  );

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        className="mx-[3rem] mt-[1rem] py-6 sm:w-full sm:p-4"
        classNames={{
          closeButton: 'hidden',
          wrapper: 'items-start sm:items-center',
        }}
        size="5xl"
        radius="lg">
        <ModalContent>
          {onClose => (
            <>
              <ModalBody className="flex flex-col sm:grid sm:grid-cols-2 sm:p-8">
                <QuickViewCarousal selectedProduct={selectedVariant} />
                <QuickViewData
                  selectedProduct={selectedProduct}
                  onClose={onClose}
                  selectedVariant={selectedVariant}
                  setSelectedVariant={setSelectedVariant}
                />
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ProductQuickView;
