'use client';

import {product2} from '@/assets';
import {capitalizeEachWord, commonDisableCachingParameters} from '@/common';
import {useGetOrderQuery} from '@/services';
import {Modal, ModalBody, ModalContent} from '@heroui/react';
import {useLocale} from 'next-intl';
import Image from 'next/image';
import {useMemo, useState} from 'react';
import {FaCheck} from 'react-icons/fa';
import {CustomButton} from '../common';

const OrderDetailsModal = ({
  isOpen,
  onClose,
  t,
  orderNumber,
  handleOpenCancellationModal,
}) => {
  const locale = useLocale();
  const [showAll, setShowAll] = useState(false);
  const {data: order, error: getOrderError} = useGetOrderQuery(
    {
      orderNumber,
      params: {
        include: 'payments,shipping_address,line_items.variant.images',
        locale,
      },
    },
    commonDisableCachingParameters,
  );

  const currentStep = useMemo(() => {
    const isShoppingMethodNotDelivery = Number(order?.shoppingMethod) !== 1;

    if (
      order?.shipmentState ===
      (isShoppingMethodNotDelivery ? 'picked' : 'delivered')
    ) {
      return 4;
    }

    const isReadyToPick = order?.shipmentState === 'ready_to_pick';
    const isPickedOrOnTheWay =
      order?.shipmentState === 'picked' ||
      order?.shipmentState === 'on_the_way';

    if (
      isShoppingMethodNotDelivery
        ? isReadyToPick
        : isReadyToPick || isPickedOrOnTheWay
    ) {
      return 3;
    }

    return 2;
  }, [order]);

  const isOrderCancellationDisabled = useMemo(
    () =>
      order?.shipmentState === 'canceled' || order?.shipmentState !== 'pending',
    [order],
  );
  const steps = [
    {id: 1, name: 'ordered'},
    {id: 2, name: 'ready_to_ship'},
    {
      id: 3,
      name: Number(order?.shoppingMethod) === 1 ? 'delivered' : 'picked',
    },
  ];

  if (!order) return null;

  const {
    images,
    lineItems,
    paymentMethods,
    shippingAddress,
    itemTotal,
    subTotal,
    taxTotal,
  } = order;

  const uniquePaymentMethods = new Set(paymentMethods);
  if (getOrderError) {
    throw new Error('Failed to load order.');
  }

  return (
    <Modal
      isOpen={isOpen && orderNumber}
      size="5xl"
      onClose={onClose}
      classNames={{
        closeButton:
          '!text-[22px] !text-black  me-4 font-main !p-0 !rounded-md mt-4 !hidden sm:!block',
        wrapper: 'items-center',
      }}
      className="bar-hide h-[75vh] overflow-y-auto rounded-[28px] md:h-[90vh]">
      <ModalContent>
        <ModalBody
          className={`sm:!flex-row sm:gap-4 sm:!px-[2.5rem] sm:!py-[3rem]`}>
          <div className={`mb-4 sm:mb-0 sm:w-2/5`}>
            <div
              className={`grid ${images?.length === 1 ? 'grid-cols-1' : 'grid-cols-2'} ${images?.length > 2 ? `grid-rows-${Math.ceil((images?.length || 0) / 2)}` : `sm:grid-rows-2`} gap-8 sm:h-full sm:gap-12`}>
              {images?.length > 0 ? (
                images?.map((image, index) => (
                  <div key={index} className={`mt-6 sm:mt-0`}>
                    <Image
                      src={image ? `${image}` : product2}
                      alt="product image"
                      width={300}
                      height={300}
                      className="h-full w-full"
                    />
                  </div>
                ))
              ) : (
                <p className="mt-2 w-[50%] text-center font-main text-lg text-persianIndigo">
                  {t('no_images')}
                </p>
              )}
            </div>
          </div>
          <div
            className={`mb-2 sm:mb-0 sm:flex sm:h-full sm:w-3/5 sm:flex-col sm:bg-lightPurple sm:p-[1rem]`}>
            {order?.shipmentState !== 'canceled' && (
              <div className="flex justify-between">
                {steps.map((step, index) => (
                  <div
                    key={step.id}
                    className={`${index === steps.length - 1 ? '' : 'flex-grow'}`}>
                    <div
                      className={`${step.id === 3 ? 'justify-self-start' : 'justify-center'} flex items-center`}>
                      <div
                        className={`flex h-10 w-10 items-center justify-center rounded-full border-2 ${
                          +currentStep > step.id
                            ? 'border-thistle bg-thistle text-white'
                            : 'border-thistle text-thistle'
                        }`}>
                        {+currentStep > step.id ? <FaCheck size={25} /> : ''}
                      </div>
                      {index < steps.length - 1 && (
                        <div className="h-[0.125rem] flex-1 bg-thistle"></div>
                      )}
                    </div>
                    <div key={index} className="flex flex-col">
                      <div className="font-secondary text-[12px] text-persianIndigo xl:text-[1vw]">
                        {t(step.name)}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div className="mb-2 mt-4 flex items-center justify-between">
              <p className="font-main text-[16px] font-medium text-persianIndigo xl:text-[1.5vw]">
                {t('order_summary')}
              </p>
              <p className="font-main font-medium text-persianIndigo xl:text-[1.3vw]">
                {Number(itemTotal)} {t('sar')}
              </p>
            </div>
            <div>
              {(showAll ? lineItems : lineItems?.slice(0, 2))?.map(
                (item, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between">
                    <p className="font-secondary text-[16px] font-normal text-persianIndigo">
                      {item.quantity} x {capitalizeEachWord(item.name)}
                      {item.color ? ' - ' : ''}
                      {capitalizeEachWord(item.color)}
                    </p>
                    <p className="font-secondary text-[16px] font-normal text-persianIndigo">
                      {item.price} {t('sar')}
                    </p>
                  </div>
                ),
              )}
              {(showAll || lineItems?.length < 2) && (
                <>
                  <div className="flex items-center justify-between">
                    <p className="font-secondary text-[16px] font-normal text-persianIndigo">
                      {t('subtotal')}
                    </p>
                    <p className="font-secondary text-[16px] font-normal text-persianIndigo">
                      {Number(subTotal).toFixed(2)} {t('sar')}
                    </p>
                  </div>
                  <div className="flex items-center justify-between">
                    <p className="font-secondary text-[16px] font-normal text-persianIndigo">
                      {t('tax_total')}
                    </p>
                    <p className="font-secondary text-[16px] font-normal text-persianIndigo">
                      {Number(taxTotal).toFixed(2)} {t('sar')}
                    </p>
                  </div>
                </>
              )}
              <div className="text-right">
                {lineItems.length > 2 && (
                  <button
                    className="text mt-2 font-secondary text-[14px] font-normal text-persianIndigo underline"
                    onClick={() => setShowAll(prev => !prev)}>
                    {showAll ? t('view_less') : t('view_all')}
                  </button>
                )}
              </div>
            </div>
            <hr className="my-4 border-0 border-t border-white" />
            <p className="mb-4 font-main text-[16px] font-medium text-persianIndigo xl:text-[1.5vw]">
              {t('shipping_address')}
            </p>
            {shippingAddress.map((address, index) => (
              <div key={index}>
                <p className="font-secondary text-[14px] font-normal text-persianIndigo">
                  {address.firstName} {address.lastName},&nbsp;&nbsp;
                  {address.phone}
                </p>
                <p className="font-secondary text-[14px] font-normal text-persianIndigo">
                  {address.address}
                </p>
                <p className="font-secondary text-[14px] font-normal text-persianIndigo">
                  {address.city}, {address.countryName}
                </p>
              </div>
            ))}
            <hr className="my-4 border-0 border-t border-white" />
            {order?.shipmentState !== 'canceled' && (
              <>
                <p className="mb-4 font-main text-[16px] font-medium text-persianIndigo xl:text-[1.5vw]">
                  {t('payment_method')}
                </p>
                <p className="font-secondary text-[14px] font-normal text-persianIndigo">
                  {Array.from(uniquePaymentMethods).join(', ')}
                </p>
              </>
            )}

            <hr className="my-4 border-0 border-t border-white" />
            {!isOrderCancellationDisabled && (
              <CustomButton
                btnType="tertiary"
                value={t('cancel_order')}
                onClickHandling={handleOpenCancellationModal}
                btnStyles={`self-end underline text-persianIndigo`}
              />
            )}
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default OrderDetailsModal;
