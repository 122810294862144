'use client';

import {
  commonDisableCachingParameters,
  rules,
  showErrorToast,
  showSuccessToast,
} from '@/common';
import {useGetUserWithAddressesQuery, useUpdateUserMutation} from '@/services';
import {useFormik} from 'formik';
import {useLocale, useTranslations} from 'next-intl';
import {useEffect} from 'react';
import * as Yup from 'yup';
import {ProfileTabs, UserInfo} from '.';

const ProfileMain = () => {
  const locale = useLocale();
  const {
    data: userData,
    isLoading: isGettingUserAddressLoading,
    error: getUserWithAddressesError,
  } = useGetUserWithAddressesQuery({locale}, commonDisableCachingParameters);

  const [updateUser, {isLoading: isUpdatingUserInfo}] = useUpdateUserMutation();
  const t = useTranslations();

  const validationSchema = Yup.object({
    firstName: rules('requiredField', null, t),
    phoneNumber: rules('updatePhoneNumber', null, t),
    email: rules('email', null, t),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      phoneNumber: '',
      email: '',
      state: '',
      city: '',
      image: '',
      dateOfBirth: '',
      gender: '',
    },
    validationSchema,
    onSubmit: async values => {
      const response = await updateUser({
        user: {
          email: values.email,
          first_name: values.firstName,
          city: values.city,
          phone_number: `966${values.phoneNumber}`,
          birthday: values.dateOfBirth,
          gender: values.gender,
        },
        image: values.image,
      });
      if (response?.error) {
        showErrorToast(response?.error?.error);
      } else {
        showSuccessToast(t('account_has_been_updated'));
      }
    },
  });
  useEffect(() => {
    if (userData) {
      formik.setValues({
        firstName: userData.user.firstName || '',
        lastName: userData.user.lastName || '',
        phoneNumber: userData.user.phoneNumber.slice(3) || '',
        email: userData.user.email || '',
        city: userData.user.city || '',
        image: userData.user.profileImage || '',
        dateOfBirth: userData.user.birthday || '',
        gender: userData.user.gender || '',
      });
    }
  }, [userData]);

  return (
    <div className="flex h-auto flex-col items-center justify-center bg-white pb-[7rem] md:pb-8">
      <form onSubmit={formik.handleSubmit}>
        <div className="gap-5 sm:grid sm:grid-cols-4">
          <UserInfo
            isUserVerified={userData?.user.isUserVerified}
            formik={formik}
            userName={userData?.user.firstName}
            userBalance={userData?.user.walletBalance}
            userPhoneNumber={userData?.user.phoneNumber}
          />
          <ProfileTabs
            formik={formik}
            isUpdatingUserInfo={isUpdatingUserInfo}
            isUserDataLoading={isGettingUserAddressLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default ProfileMain;
