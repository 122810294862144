'use client';
import {convertEnglishNumbersToArabic} from '@/common';
import {Popover, PopoverContent, PopoverTrigger, Slider} from '@heroui/react';
import {useLocale} from 'next-intl';
import {useCallback, useState, useEffect} from 'react';
import {IoMdClose} from 'react-icons/io';

const Filters = ({
  filters,
  t,
  getFilterValues,
  setFilterValues,
  onPriceRangeChange,
  highestPrice,
  isFiltersVisible,
}) => {
  const [localSelectedFilterOption, setLocalSelectedFilterOption] = useState(
    {},
  );
  const [priceRange, setPriceRange] = useState([0, highestPrice]);
  const [isOpen, setIsOpen] = useState(false);
  const locale = useLocale();
  const handleOptionClick = useCallback((filterName, option) => {
    setLocalSelectedFilterOption(prevState => {
      const existingOptions = prevState[filterName] || [];
      const isSelected = existingOptions.includes(option);
      const updatedOptions = isSelected
        ? existingOptions.filter(o => o !== option)
        : [...existingOptions, option];
      return {...prevState, [filterName]: updatedOptions};
    });
  }, []);

  useEffect(() => {
    if (setFilterValues) {
      setFilterValues(localSelectedFilterOption);
    }
  }, [localSelectedFilterOption, setFilterValues]);

  useEffect(() => {
    if (onPriceRangeChange) {
      onPriceRangeChange(priceRange);
    }
  }, [priceRange, onPriceRangeChange]);

  const handlePriceRangeChange = values => {
    setPriceRange(values);
  };

  return (
    <Popover
      showArrow={isOpen}
      isOpen={isOpen}
      onOpenChange={open => setIsOpen(open)}
      placement="bottom-end"
      classNames={{
        content: 'p-0',
      }}>
      <div
        className={`${
          isFiltersVisible ? 'max-h-96' : 'max-h-0'
        } mb-4 flex flex-col justify-end overflow-hidden px-6 transition-[max-height] duration-500 ease-in-out sm:px-[9.5vw]`}>
        <div className="flex justify-end">
          {filters?.data
            .filter(item => item.attributes.name === 'price')
            .map((item, index) => (
              <PopoverTrigger key={index}>
                <button
                  className={`${isOpen && isFiltersVisible ? 'justify-between border-2 !border-persianIndigo' : 'justify-center border-2 !border-thistle'} flex w-fit items-center gap-4 rounded-full px-4 py-1 text-[12px] filter focus-visible:outline-none`}>
                  <span className="flex items-center justify-between font-secondary text-persianIndigo filter">
                    {item.attributes.presentation}
                  </span>
                  {isOpen && (
                    <div className="relative top-[-1px] rounded-full border-1 border-persianIndigo filter">
                      <IoMdClose
                        size={12}
                        className="font-secondary font-bold text-persianIndigo filter"
                      />
                    </div>
                  )}
                </button>
              </PopoverTrigger>
            ))}
        </div>

        {filters?.data
          .filter(item => item.attributes.name === 'price')
          .map((item, index) => (
            <PopoverContent key={index} className="ms-auto">
              {isOpen && (
                <div className={`overflow-hidden`}>
                  <div className="flex flex-col text-persianIndigo">
                    {item.attributes.name === 'price' ? (
                      <>
                        <div className="w-[90vw] rounded-[8px] border-[1px] border-thistle bg-white px-6 pt-4 sm:w-[40vw] sm:px-[2.5rem]">
                          <Slider
                            label={`${convertEnglishNumbersToArabic(0, locale)} ${t('sar')}`}
                            showTooltip
                            tooltipProps={{
                              shadow: 'none',
                              placement: 'bottom',
                              showArrow: false,
                              classNames: {
                                base: ['bg-transparent'],
                                content: [
                                  'py-2 px-4 shadow-none  flex flex-row-reverse gap-1',
                                  "text-persianIndigo rtl:before:content-['ر.س'] ltr:before:content-['SAR'] priceTag font-secondary font-bold bg-transparent border-none",
                                ],
                              },
                            }}
                            step={1}
                            size="sm"
                            minValue={0}
                            maxValue={highestPrice}
                            defaultValue={priceRange}
                            getValue={() =>
                              `${convertEnglishNumbersToArabic(highestPrice, locale)} ${t('sar')}`
                            }
                            onChange={handlePriceRangeChange}
                            color="foreground"
                            classNames={{
                              track: 'bg-white',
                              filler: 'bg-thistle',
                              thumb: 'bg-thistle',
                              trackWrapper: 'mb-[2.5rem] px-[2.25rem]',
                              label: 'sm:text-[10px] font-secondary',
                              value: `sm:text-[10px] font-secondary`,
                              mark: 'text-persianIndigo',
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        {getFilterValues(item.id).length === 0 ? (
                          <p className="mb-4 text-center font-secondary text-persianIndigo">
                            {t('no_filters_available')}
                          </p>
                        ) : (
                          <>
                            <p className="px-2 font-secondary text-[14px] capitalize text-persianIndigo">
                              {item.attributes.presentation}
                            </p>
                            <div className="mb-2 flex flex-wrap justify-center gap-2">
                              {getFilterValues(item.id).map((option, index) => {
                                return (
                                  <div
                                    key={index}
                                    className={`${
                                      localSelectedFilterOption[
                                        item.attributes.name
                                      ]?.includes(option.attributes.name)
                                        ? 'border-black'
                                        : 'border-transparent'
                                    } cursor-pointer rounded-full border-1 p-2`}
                                    onClick={() =>
                                      handleOptionClick(
                                        item.attributes.name,
                                        option.attributes.name,
                                      )
                                    }>
                                    <div
                                      className={`${item.attributes.name === 'size' ? 'h-[1.2rem] w-[1.2rem] text-[0.8rem] font-bold' : 'h-[1.2rem] w-[1.2rem]'} flex justify-center rounded-full`}
                                      style={{
                                        backgroundColor:
                                          item.attributes.name === 'color' &&
                                          option.attributes.name,
                                        border:
                                          option.attributes.name === '#ffffff'
                                            ? '1px solid #D3D3D3'
                                            : 'none',
                                      }}>
                                      {item.attributes.name === 'size' &&
                                        option.attributes.name}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </PopoverContent>
          ))}
      </div>
    </Popover>
  );
};

export default Filters;
