import {
  anooshPartner,
  erminePartner,
  godivaPartner,
  lavivianePartner,
  patchiPartner,
} from '@/assets';
import {useTranslations} from 'next-intl';
import Image from 'next/image';

const Partners = () => {
  const t = useTranslations();
  return (
    <div className="mb-[2.5rem] sm:my-[3rem] sm:px-[9.17vw]">
      <p className="mb-[2vw] ps-8 font-bentogaThin text-[20px] font-thin text-spanishViolet sm:my-16 sm:ps-0 sm:text-start sm:text-[clamp(16px,2.86vw,55px)]">
        {t('our_partners')}
      </p>
      <div className="flex items-center justify-center gap-2 sm:flex-wrap">
        <Image
          src={anooshPartner}
          width={168}
          height={168}
          alt="anoosh partner"
          className="w-[14.47vw] sm:w-[12.81vw]"
        />
        <Image
          src={patchiPartner}
          width={168}
          height={168}
          alt="patchi partner"
          className="w-[14.47vw] sm:w-[12.81vw]"
        />
        <Image
          src={godivaPartner}
          width={168}
          height={168}
          alt="godiva partner"
          className="w-[14.47vw] sm:w-[12.81vw]"
        />
        <Image
          src={erminePartner}
          width={168}
          height={168}
          alt="ermine partner"
          className="w-[14.47vw] sm:w-[12.81vw]"
        />
        <div className="inline rounded-full border-1 border-persianIndigo">
          <Image
            src={lavivianePartner}
            width={168}
            height={168}
            alt="laviviane partner"
            className="relative z-[-1] w-[14.47vw] sm:h-full sm:w-[12.81vw]"
          />
        </div>
      </div>
    </div>
  );
};

export default Partners;
