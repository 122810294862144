'use client';

import useEmblaCarousel from 'embla-carousel-react';
import {useLocale, useTranslations} from 'next-intl';
import React, {useEffect, useCallback, useRef} from 'react';
import {AiOutlineGift} from 'react-icons/ai';
import {LuMapPin} from 'react-icons/lu';
import {TbTruckDelivery} from 'react-icons/tb';

const VerticalLabels = () => {
  const locale = useLocale();
  const [emblaRef, emblaApi] = useEmblaCarousel({
    axis: 'x',
    loop: true,
    dragFree: false,
    direction: locale === 'ar' ? 'rtl' : 'ltr',
  });

  const autoplayInterval = useRef(null);
  const t = useTranslations();

  const startAutoplay = useCallback(() => {
    if (!emblaApi) return;
    autoplayInterval.current = setInterval(() => {
      if (emblaApi.canScrollNext()) {
        emblaApi.scrollNext();
      } else {
        emblaApi.scrollTo(0);
      }
    }, 3000);
  }, [emblaApi]);

  const stopAutoplay = useCallback(() => {
    if (autoplayInterval.current) {
      clearInterval(autoplayInterval.current);
    }
  }, []);

  useEffect(() => {
    if (emblaApi) {
      startAutoplay();
      emblaApi.on('reInit', startAutoplay);
    }

    return () => stopAutoplay();
  }, [emblaApi, startAutoplay, stopAutoplay]);

  const messages = [
    {
      text: 'no_address_hassle',
      icon: <LuMapPin className="text-thistle" />,
    },
    {
      text: 'free_delivery',
      icon: <TbTruckDelivery className="text-thistle" />,
    },
    {
      text: 'premium_flowers',
      icon: <AiOutlineGift className="text-thistle" />,
    },
  ];

  return (
    <div
      className="rtl:direction-rtl w-[136px] overflow-hidden sm:hidden"
      ref={emblaRef}>
      <div className="flex">
        {messages.map((message, index) => (
          <div
            key={index}
            className="flex min-w-full items-center justify-start gap-2">
            {message.icon}
            <p className="text-[10px] font-semibold text-persianIndigo">
              {t(message.text)}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VerticalLabels;
