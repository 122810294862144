'use client';
import {convertEnglishNumbersToArabic} from '@/common';
import {CustomButton} from '@/components/common';
import {useApplyCartActions} from '@/hooks';
import {Button} from '@heroui/react';
import {useLocale, useTranslations} from 'next-intl';
import React, {useState, useMemo} from 'react';
import {useDispatch} from 'react-redux';

const QuickViewData = ({
  selectedProduct,
  onClose,
  selectedVariant,
  setSelectedVariant,
}) => {
  const t = useTranslations();
  const locale = useLocale();

  const {debouncedApplyCartAction} = useApplyCartActions();
  const selectedColor = useMemo(
    () => selectedVariant?.optionsText?.split(' ')[1],
    [selectedVariant],
  );

  const handleVariantSelect = variant => {
    setSelectedVariant(variant);
  };
  const handleAddingToCart = () => {
    debouncedApplyCartAction('add', selectedVariant);
    onClose();
  };

  return (
    <div
      className={`${locale === 'ar' ? 'sm:mr-[2.5rem]' : 'sm:ml-[2.5rem]'} flex flex-col justify-between`}>
      <div>
        <div className="mb-[0.5rem] flex items-center justify-between">
          <h3 className="font-main text-[1rem] font-medium text-persianIndigo sm:text-[1.25rem]">
            {selectedProduct.name}
          </h3>
          <CustomButton
            btnType="tertiary"
            value={'X'}
            btnStyles="text-[1.375rem] text-persianIndigo !hover:!bg-none hidden sm:block"
            onClickHandling={onClose}
          />
        </div>
        <div className="mb-[1rem] font-main text-[0.7rem] font-medium text-persianIndigo sm:text-[1rem]">
          {convertEnglishNumbersToArabic(selectedProduct.price, locale)}{' '}
          {t('sar')}
        </div>
        {selectedProduct?.variants?.[0]?.optionValues?.length > 0 && (
          <div className="mb-[1rem] border-y-2 border-darkWhite py-[1rem]">
            <h6 className="mb-[0.5rem] font-secondary text-[0.7rem] font-normal text-persianIndigo sm:text-[0.9rem]">
              {t('select_color')}:
              <span className="font-bold capitalize text-persianIndigo">{`${' ' + selectedVariant.optionsText.split(' ')[1]}`}</span>
            </h6>
            <div className="flex flex-row gap-[0.75rem]">
              {selectedProduct.variants
                .filter(v => v.inStock)
                .map(variant => {
                  const displayName = variant.optionsText.split(' ')[1];
                  const variantColor = variant?.optionValues[0].name;
                  return (
                    <div
                      key={variant.id}
                      className={`rounded-full p-[0.2rem] ${selectedColor === displayName ? 'border' : ''}`}
                      onClick={() => handleVariantSelect(variant)}>
                      <div
                        className={`cursor-pointer rounded-full p-[0.50rem] ${variantColor === '#ffffff' ? 'border border-[#bbbbbb]' : ''}`}
                        style={{backgroundColor: variantColor}}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        )}
        <div className="flex flex-col justify-between">
          <div
            className={`${selectedProduct?.variants?.[0]?.optionValues?.length > 0 ? '' : 'border-t-2 border-darkWhite pt-[1rem]'}`}>
            <h3 className="mb-[1rem] font-secondary text-[0.8rem] font-normal text-persianIndigo sm:text-[0.9rem]">
              {t('product_details')}
            </h3>
            <div
              className="h-auto font-secondary text-[0.7rem] font-[100] text-persianIndigo sm:h-[9.3125rem] sm:text-[0.8rem]"
              dangerouslySetInnerHTML={{
                __html: selectedProduct.description
                  ? selectedProduct.description
                  : t('this_product_has_no_description'),
              }}
            />
          </div>
        </div>
      </div>
      <div className="mt-[2.375rem] flex justify-center">
        <CustomButton
          onClickHandling={handleAddingToCart}
          btnStyles="w-[17.0625rem]"
          value={t('add_to_cart')}
        />
      </div>
    </div>
  );
};

export default QuickViewData;
