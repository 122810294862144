import {useLocale, useTranslations} from 'next-intl';

const CategoryList = ({
  categories,
  selectedCategory,
  handleCategoryClick,
  categoryId,
}) => {
  const t = useTranslations();
  const locale = useLocale();
  return (
    <div className="mx-auto w-[50%] sm:w-[30vw]">
      <h2 className="font-semiBold mb-4 font-main text-persianIndigo sm:text-[18px] xl:text-[1vw]">
        {t('categories')}
      </h2>
      <hr className="border-0 border-t border-thistle" />
      <ul>
        <li
          className={`py-6 ps-2 font-secondary text-persianIndigo ${
            selectedCategory === categoryId ? 'font-bold' : 'font-normal'
          }`}>
          <button
            className={`${locale === 'en' ? 'text-left' : 'text-right'} w-full xl:text-[0.9vw]`}
            onClick={() => handleCategoryClick(categoryId)}>
            {t('all')}
          </button>
        </li>
        <hr className="border-0 border-t border-thistle" />
        {categories?.map((item, index) => (
          <div key={index}>
            <li
              className={`my-6 ps-2 font-secondary text-persianIndigo sm:text-[14px] xl:text-[0.9vw] ${
                selectedCategory === item.id ? 'font-bold' : 'font-normal'
              }`}>
              <button
                className={`${locale === 'en' ? 'text-left' : 'text-right'} w-full xl:text-[0.9vw]`}
                onClick={() => handleCategoryClick(item.id)}>
                {item.attributes.name}
              </button>
            </li>
            <hr className="border-0 border-t border-thistle" />
          </div>
        ))}
      </ul>
    </div>
  );
};

export default CategoryList;
