'use client';

import {
  capitalizeEachWord,
  convertEnglishNumbersToArabic,
  renderStars,
} from '@/common/helpers';
import {useLocale} from 'next-intl';
import Image from 'next/image';
import {GoHeartFill} from 'react-icons/go';
import {Pagination} from '@heroui/react';
import {CustomButton} from '../common';
import {useState} from 'react';
import Link from 'next/link';

const FavoriteProductsList = ({products, t, storeId, applyOrRemoveFavItem}) => {
  const locale = useLocale();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  const totalPages = Math.ceil(products?.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProducts = products.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="rlt:sm:!mr-8 w-full sm:ltr:!mr-0">
      {currentProducts?.length > 0 ? (
        <>
          <div className="grid grid-cols-3 gap-x-4 sm:mt-0 xl:grid-cols-4">
            {currentProducts.map((product, index) => {
              const imageUrl =
                product.product.images[0]?.attributes.original_url;
              const taxonName =
                product?.product.taxonInfo[0]?.attributes?.permalink;

              return (
                <div key={index} className="mb-8 flex flex-col justify-between">
                  <div className="relative h-full">
                    <div className="absolute top-[10px] sm:top-[16px] ltr:left-[10px] sm:ltr:left-[16px] rtl:right-[10px] sm:rtl:right-[16px]">
                      <CustomButton
                        iconOnly
                        onClickHandling={() => applyOrRemoveFavItem(product)}
                        btnStyles="bg-white !p-[4px] !rounded-sm cursor-pointer !h-[30px] !w-[30px] !min-w-[30px]"
                        value={
                          <GoHeartFill className="text-[22px] font-bold text-pink" />
                        }
                      />
                    </div>
                    <Link
                      href={`/${locale}/${taxonName}/${product?.product?.slug}/${product.product.id}?sid=${storeId}`}>
                      <Image
                        src={`${imageUrl}`}
                        alt={product.product.name}
                        width={600}
                        height={400}
                        className="h-[8.03rem] w-full cursor-pointer rounded-[16px] object-fill sm:h-full"
                      />
                    </Link>
                    {(!product.product.inStock ||
                      !product.product.inBranchStock) && (
                      <div className="absolute bottom-0 w-full cursor-pointer">
                        <p className="relative bg-white bg-opacity-50 py-2 text-center font-main text-[16px] font-thin text-persianIndigo sm:py-5">
                          {t('out_of_stock')}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="mt-2">
                    <div className="flex items-center justify-between">
                      <p className="font-main text-[12px] font-medium text-persianIndigo xl:text-[1vw]">
                        {convertEnglishNumbersToArabic(
                          Number(product.product.price),
                          locale,
                        )}{' '}
                        {t('sar')}
                      </p>
                      {product.product.totalRating > 0 && (
                        <div className="flex items-center text-[12px] sm:text-[16px]">
                          {renderStars(product.product.totalRating, 0, 0, 2)}
                          <span className="mt-1 font-secondary text-persianIndigo ltr:ml-2 rtl:mr-2">
                            {convertEnglishNumbersToArabic(
                              product.product.totalRating,
                              locale,
                            )}
                          </span>
                        </div>
                      )}
                    </div>
                    <h3 className="font-secondary text-[12px] font-normal text-persianIndigo sm:text-[12px] xl:text-[0.8vw]">
                      {capitalizeEachWord(product.product.name)}
                    </h3>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex w-full justify-center">
            <Pagination
              total={totalPages}
              initialPage={1}
              page={currentPage}
              onChange={page => setCurrentPage(page)}
              isCompact
              showControls={true}
              classNames={{
                cursor: 'hidden',
                wrapper: 'shadow-none text-persianIndigo',
                item: 'hidden',
                prev: 'bg-white shadow-none text-persianIndigo',
                next: 'bg-white shadow-none text-persianIndigo',
              }}
            />
          </div>
        </>
      ) : (
        <div className="mt-[3.75rem] flex h-[75vh] flex-col items-center justify-start xl:mt-0 xl:justify-center">
          <GoHeartFill className="text-[48px] font-bold text-pink" />
          <p className="text-center font-secondary text-[20px] text-persianIndigo">
            {t('no_favorite_products')}
          </p>
        </div>
      )}
    </div>
  );
};

export default FavoriteProductsList;
