'use client';
import {useLazyGetAllProductsQuery} from '@/services';
import {useRouter} from '@/hooks';
import {Input, ScrollShadow, Spinner} from '@heroui/react';
import debounce from 'lodash/debounce';
import {useLocale, useTranslations} from 'next-intl';
import Image from 'next/image';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {FaSearch} from 'react-icons/fa';
import {motion, AnimatePresence} from 'framer-motion';
import {useSelector} from 'react-redux';
import {getNearestStoreData} from '@/selectors';
import {useMediaQuery} from 'react-responsive';

const SearchBar = ({isSearchBarOpen}) => {
  const locale = useLocale();
  const t = useTranslations();
  const router = useRouter();
  const [searchTerm, setSearchTerm] = useState('');
  const store = useSelector(getNearestStoreData);
  const inputRef = useRef(null);
  const isMobile = useMediaQuery({query: '(max-width: 640px)'});

  const [
    getFilteredProducts,
    {data: products, isLoading: isSearchForProductsLoading},
  ] = useLazyGetAllProductsQuery();

  const filteredProducts = useMemo(() => {
    return products?.data.filter(product => {
      const hasImages = product?.relationships.images.data.length > 0;
      const isInStock = product?.attributes.in_stock === true;
      return hasImages && isInStock;
    });
  }, [products]);

  const getImageUrl = imageId => {
    const image = products?.included
      .filter(taxon => taxon.type === 'image')
      .find(img => img.id === imageId);
    return image ? image.attributes.original_url : null;
  };

  const getRelatedTaxon = taxonId => {
    const taxon = products?.included
      .filter(taxon => taxon.type === 'taxon')
      .find(taxon => taxon.id === taxonId);
    return taxon
      ? taxon.attributes.name.toLowerCase().replace(/ /g, '-')
      : null;
  };

  const debouncedSearch = useMemo(
    () =>
      debounce(value => {
        getFilteredProducts({
          'filter[search_term]': value,
          locale,
          'filter[taxon_name_not_eq]': 'AddOns,Gift Cards',
          include: 'images,taxons',
        });
      }, 300),
    [getFilteredProducts],
  );

  const isThereIsNoResults = useMemo(() => {
    return searchTerm.length > 0 && filteredProducts?.length === 0;
  }, [filteredProducts, searchTerm]);

  const isThereIsSearchResults = useMemo(() => {
    return filteredProducts?.length > 0 && searchTerm.length > 0;
  }, [filteredProducts, searchTerm]);

  const handleSearchChange = useCallback(
    event => {
      const value = event.target.value;
      debouncedSearch(value);
      setSearchTerm(event.target.value);
    },
    [debouncedSearch],
  );

  const handleKeyDown = useCallback(
    event => {
      if (event.key === 'Enter' && searchTerm.trim().length > 0) {
        router.push(`/search-results/${encodeURIComponent(searchTerm)}`);
        setSearchTerm('');
        inputRef.current?.blur();
      }
    },
    [searchTerm, router],
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleProductClick = (product, taxonName) => {
    setSearchTerm('');
    router.push(
      `/${taxonName}/${product.attributes.slug}/${product.id}?sid=${store?.id}`,
    );
  };

  const handlePrefetching = path => {
    router.prefetch(path);
  };

  return (
    <div
      className={`sm:max-h-auto my-2 transition-[max-height] duration-500 ease-in-out sm:mb-0 sm:mt-4 ${isSearchBarOpen ? 'sm:max-h-auto max-h-[300px]' : isMobile ? 'max-h-0 overflow-hidden' : ''}`}>
      <div className="mx-4 flex flex-col items-center">
        <motion.div
          initial={
            isMobile
              ? {opacity: 0, y: -10}
              : {opacity: 0, x: locale === 'ar' ? -50 : 50}
          }
          animate={isMobile ? {opacity: 1, y: 0} : {opacity: 1, x: 0}}
          exit={
            isMobile
              ? {opacity: 0, y: -10}
              : {opacity: 0, x: locale === 'ar' ? 50 : -50}
          }
          transition={{duration: 0.4, ease: 'easeOut'}}
          className="w-[80%] sm:w-full">
          <Input
            isClearable
            ref={inputRef}
            variant="bordered"
            placeholder={t('search')}
            value={searchTerm}
            startContent={<FaSearch className="text-persianIndigo" />}
            onChange={handleSearchChange}
            onClear={() => setSearchTerm('')}
            onKeyDown={handleKeyDown}
            radius="full"
            classNames={{
              label: '!text-thistle',
              inputWrapper:
                '!border-thistle hover:border-thistle focus:border-thistle bg-white focus:border-[5px] !outline-0 after:bg-persianIndigo mt-[0.3rem]',
              input: '!text-persianIndigo placeholder:text-thistle',
            }}
          />
        </motion.div>
      </div>
      {isSearchForProductsLoading && (
        <motion.div
          initial={{opacity: 0, y: -10}}
          animate={{opacity: 1, y: 0}}
          exit={{opacity: 0, y: -10}}
          transition={{duration: 0.3}}
          className="relative flex flex-col items-center">
          <div className="absolute top-[0.5rem] z-[9999] w-[80%] rounded-xl border-[0.031rem] border-thistle bg-white shadow-lg sm:w-full">
            <div className="flex h-[5rem] items-center justify-center p-4 text-persianIndigo">
              <Spinner color="secondary" />
            </div>
          </div>
        </motion.div>
      )}

      <AnimatePresence>
        {isThereIsSearchResults && (
          <motion.div
            key="search-results"
            initial={{opacity: 0, y: -10}}
            animate={{opacity: 1, y: 0}}
            exit={{opacity: 0, y: -10}}
            transition={{duration: 0.3}}
            className="relative flex flex-col items-center">
            <div className="absolute top-[0.5rem] z-[9999] w-[80%] rounded-xl border-[0.031rem] border-thistle bg-white shadow-lg xl:w-full">
              <ScrollShadow
                hideScrollBar
                className="custom-scrollbar h-[10rem] overflow-y-auto">
                {filteredProducts?.map((product, index) => {
                  const imageUrl = getImageUrl(
                    product.relationships.images.data[0]?.id,
                  );
                  const taxonName = getRelatedTaxon(
                    product?.relationships.taxons.data[0]?.id,
                  );
                  return (
                    <Fragment key={index}>
                      <div
                        className="flex cursor-pointer items-center p-4 hover:rounded-xl hover:bg-Isabelline"
                        onClick={() => handleProductClick(product, taxonName)}
                        onMouseEnter={() =>
                          handlePrefetching(
                            `/${taxonName}/${product.attributes.slug}/${product.id}`,
                          )
                        }>
                        <Image
                          src={`${imageUrl}`}
                          alt={product.attributes.name}
                          width={50}
                          height={50}
                          className="rounded-lg object-contain"
                        />
                        <div className="w-full p-3 ps-6 font-secondary text-[14px] text-persianIndigo">
                          {product.attributes.name}
                        </div>
                      </div>
                      <hr className="mx-auto w-[80%] border-0 border-t border-lightGray" />
                    </Fragment>
                  );
                })}
              </ScrollShadow>
              <div className="sticky bottom-0 rounded-xl bg-white p-4 shadow-md">
                <button
                  className="w-full rounded-lg bg-persianIndigo p-2 text-white"
                  onMouseEnter={() =>
                    router.prefetch(`/search-results/${searchTerm}`)
                  }
                  onClick={() => {
                    router.push(`/search-results/${searchTerm}`);
                    setSearchTerm('');
                  }}>
                  {t('view_all_results')}
                </button>
              </div>
            </div>
          </motion.div>
        )}
        {isThereIsNoResults && (
          <motion.div
            key="no-results"
            initial={{opacity: 0, y: -10}}
            animate={{opacity: 1, y: 0}}
            exit={{opacity: 0, y: -10}}
            transition={{duration: 0.3}}
            className="relative flex flex-col items-center">
            <div className="absolute top-[0.5rem] z-[9999] w-[80%] rounded-xl border-[0.031rem] border-thistle bg-white shadow-lg sm:w-full">
              <div className="flex h-[5rem] items-center justify-center p-4 text-persianIndigo">
                {t('no_products_found')}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default SearchBar;
