'use client';
import {rules, showErrorToast} from '@/common';
import {getIsUpdateCreditCardModalOpen} from '@/selectors/modalActionsSelectors';
import {useUpdateCreditNameMutation} from '@/services';
import {closeUpdateCreditCard, openUpdateCreditCard} from '@/slices';
import {
  Checkbox,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@heroui/react';
import {useFormik} from 'formik';
import {useLocale, useTranslations} from 'next-intl';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {CustomButton} from '../common';

const UpdateCreditCardModal = ({selectedCard}) => {
  const t = useTranslations();
  const locale = useLocale();
  const dispatch = useDispatch();
  const isUpdateCreditCardOpen = useSelector(getIsUpdateCreditCardModalOpen);
  const [updateCredit, {isLoading: isUpdatingCreditLoading}] =
    useUpdateCreditNameMutation();
  const [defaultCreditCard, setIsDefaultCreditCard] = useState(
    selectedCard?.default,
  );
  const validationSchema = Yup.object({
    creditName: rules('requiredField', null, t),
  });
  const formik = useFormik({
    initialValues: {
      creditName: selectedCard?.name,
      id: selectedCard?.id,
    },
    validationSchema,
    onSubmit: async values => {
      const response = await updateCredit({
        id: values.id,
        data: {
          name: values.creditName,
          default: defaultCreditCard,
        },
      });
      if (response?.error) {
        showErrorToast(response?.error.error);
      } else {
        onClosingUpdateCreditCardModal();
      }
    },
  });

  const onClosingUpdateCreditCardModal = () => {
    dispatch(closeUpdateCreditCard());
  };

  const onOpeningUpdateCreditCardModal = () => {
    dispatch(openUpdateCreditCard());
  };

  return (
    <>
      <Modal
        size="2xl"
        className="p-12"
        isOpen={isUpdateCreditCardOpen}
        onOpenChange={onOpeningUpdateCreditCardModal}
        onClose={onClosingUpdateCreditCardModal}
        classNames={{
          closeButton:
            '!text-[22px] !text-persianIndigo hover:!bg-thistle font-main !p-0 !rounded-md mt-4 !hidden sm:block',
          wrapper: 'items-center',
        }}>
        <ModalContent>
          {onClose => (
            <form onSubmit={formik.handleSubmit}>
              <ModalHeader className="flex justify-center">
                <span className="w-[260px] border-b-4 border-thistle pb-[10px] text-center font-main text-[22px] font-medium text-persianIndigo">
                  {t('update_credit_card')}
                </span>
              </ModalHeader>
              <ModalBody>
                <p className="text-center font-secondary text-persianIndigo">
                  {t('you_can_only_update_your_credit_card_name')}
                </p>
                <div className="mb-3 font-secondary text-persianIndigo">
                  <Input
                    type="text"
                    variant="underlined"
                    name="creditName"
                    label={t('credit_name')}
                    value={formik.values.creditName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full text-[16px]"
                    classNames={{
                      input:
                        'text-[16px] !text-persianIndigo font-secondary font-normal',
                      label:
                        'text-[16px] font-secondary !text-thistle font-normal',
                      inputWrapper:
                        '!border-thistle hover:border-thistle focus:border-thistle focus:border-[5px] !outline-0 after:bg-persianIndigo',
                    }}
                  />
                  {formik.touched.creditName && formik.errors.creditName ? (
                    <div
                      className={`${locale === 'ar' ? 'mr-3' : 'ml-3'} mt-2 font-secondary text-[12px] text-red-500`}>
                      {formik.errors.creditName}
                    </div>
                  ) : null}
                </div>
                <Checkbox
                  radius="full"
                  isSelected={defaultCreditCard}
                  color="secondary"
                  className="!ms-[-10px]"
                  onValueChange={setIsDefaultCreditCard}>
                  <span className="font-secondary text-[16px] font-normal text-persianIndigo">
                    {t('set_as_default_credit_card')}
                  </span>
                </Checkbox>
              </ModalBody>
              <ModalFooter>
                <CustomButton
                  btnStyles="w-full"
                  isLoading={isUpdatingCreditLoading}
                  btnActionType="submit"
                  value={t('update')}
                />
              </ModalFooter>
            </form>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default UpdateCreditCardModal;
