import {useTranslations} from 'next-intl';
import React from 'react';

const HomeRibbon = () => {
  const t = useTranslations();
  return (
    <div className="ribbon-image relative h-[150px] bg-spanishViolet text-center sm:my-[8rem] sm:h-[450px]">
      <div className="absolute inset-0 z-10 bg-spanishViolet bg-opacity-80" />
      <div className="relative z-20 flex h-full flex-col items-center justify-center gap-4 text-white">
        <h2 className="font-bentogaThin text-[28px] sm:text-[36px] xl:text-[70px]">
          {t('spring_rose')}
        </h2>
        <p className="font-secondary text-[16px] font-light sm:text-[24px] xl:text-[30px]">
          {t('say_it_with_love_say_it_with_spring_rose')}
        </p>
      </div>
    </div>
  );
};

export default HomeRibbon;
