'use client';

import {UseAddOrRemoveFav} from '@/hooks';
import {useTranslations} from 'next-intl';
import {useParams} from 'next/navigation';
import {useMediaQuery} from 'react-responsive';
import {FavoriteProductsList} from '../Category';

const NavbarFavorites = () => {
  const t = useTranslations();
  const {locale} = useParams();

  const {applyOrRemoveFavItem, localFavData, isFavProductsAreLoading, store} =
    UseAddOrRemoveFav();

  const isMobile = useMediaQuery({query: '(max-width: 640px)'});

  return (
    <>
      <div className="pb-32">
        {isMobile ? (
          <div className="mb-8 flex-col">
            <h1 className="mx-4 font-main text-[18px] font-normal text-persianIndigo">
              {t('home')} &gt; {t('favorites')}
            </h1>
            <div className="mx-4 mt-6">
              <FavoriteProductsList
                applyOrRemoveFavItem={applyOrRemoveFavItem}
                products={localFavData}
                t={t}
                locale={locale}
                storeId={store?.id}
              />
            </div>
          </div>
        ) : (
          <>
            <div className="relative mx-[4rem] flex justify-end py-4 sm:gap-4 xl:mx-0 xl:px-16">
              <div className="mb-8 flex w-full items-center justify-between">
                <h1 className="font-main text-[18px] font-normal text-persianIndigo">
                  {t('home')} &gt; {t('favorites')}
                </h1>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row sm:!space-x-0 sm:px-16 sm:ltr:space-x-8 sm:rtl:space-x-0">
              <div className="flex sm:flex-col"></div>
              <FavoriteProductsList
                applyOrRemoveFavItem={applyOrRemoveFavItem}
                products={localFavData}
                t={t}
                locale={locale}
                storeId={store?.id}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default NavbarFavorites;
