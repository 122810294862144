'use client';

import {profile} from '@/assets';
import {commonDisableCachingParameters} from '@/common';
import {useGetUserWithAddressesQuery} from '@/services';
import {openLogIn} from '@/slices';
import {NavbarItem} from '@heroui/react';
import {useTranslations} from 'next-intl';
import Image from 'next/image';
import React, {memo, useEffect} from 'react';

const UserProfile = memo(
  ({isLoggedIn, dispatch, router, locale, setProfileName}) => {
    const {
      data: userData,
      isLoading: isGettingUserAddressLoading,
      error: getUserWithAddressesError,
    } = useGetUserWithAddressesQuery(
      {locale},
      {...commonDisableCachingParameters, skip: !isLoggedIn},
    );
    const t = useTranslations();

    useEffect(
      () => setProfileName(userData?.user?.firstName),
      [userData, isGettingUserAddressLoading, setProfileName],
    );

    const handleProfile = () =>
      isLoggedIn ? router.push(`/profile`) : dispatch(openLogIn());
    return (
      <NavbarItem className="cursor-pointer">
        <div onClick={handleProfile}>
          {isLoggedIn && userData?.user.profileImage ? (
            <div className="flex flex-col items-center justify-end gap-2 rounded-full sm:h-[50px] sm:w-[50px]">
              <Image
                src={`${userData?.user.profileImage}`}
                width={50}
                height={50}
                className="h-full w-full rounded-full"
                alt="user profile image"
              />
            </div>
          ) : (
            <div className="h-6 w-6">
              <Image
                src={profile}
                width={56}
                height={56}
                alt="profile"
                className="cursor-pointer"
              />
            </div>
          )}
        </div>
      </NavbarItem>
    );
  },
);
UserProfile.displayName = 'UserProfile';

export default UserProfile;
