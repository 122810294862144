'use client';
import {Tab, Tabs} from '@heroui/react';
import React, {useState} from 'react';
import {AccountTab, AddressesTab, CardsTab, FavoritesTab, OrdersTab} from '.';
import {useTranslations} from 'next-intl';
import {useMediaQuery} from 'react-responsive';

const ProfileTabs = ({formik, isUpdatingUserInfo, isUserDataLoading}) => {
  const t = useTranslations();
  const [selectedTab, setSelectedTab] = useState('account');
  const isMobile = useMediaQuery({query: '(max-width: 640px)'});

  return isMobile ? (
    <div className="flex w-full justify-center bg-white">
      <div className="col-span-3 flex w-[75%] flex-col rounded-md border border-thistle bg-white px-[5vw] py-[30px]">
        <Tabs
          aria-label="Options"
          variant="underlined"
          selectedKey={selectedTab}
          fullWidth
          onSelectionChange={setSelectedTab}
          classNames={{
            tabContent:
              'text-[13px] md:text-[18px]  group-data-[selected=true]:text-persianIndigo font-main font-normal text-persianIndigo',
            tabList: 'gap-0 border-b-1 border-thistle pb-[30px]',
            cursor: 'w-[102%] bottom-[-30px] bg-persianIndigo',
          }}>
          <Tab key="account" title={t('account')}>
            <AccountTab
              formik={formik}
              isUpdatingUserInfo={isUpdatingUserInfo}
              isUserDataLoading={isUserDataLoading}
            />
          </Tab>
          <Tab key="cards" title={t('my_cards')}>
            <CardsTab />
          </Tab>
          <Tab key="orders" title={t('order_history')}>
            <OrdersTab />
          </Tab>
          <Tab key="addresses" title={t('addresses')}>
            <AddressesTab />
          </Tab>
          <Tab key="favorites" title={t('favorites')}>
            <FavoritesTab />
          </Tab>
        </Tabs>
      </div>
    </div>
  ) : (
    <div className="col-span-3 flex w-full flex-col bg-white px-[55px] py-[30px] sm:w-[90vw] xl:w-[65vw]">
      <Tabs
        aria-label="Options"
        variant="underlined"
        selectedKey={selectedTab}
        fullWidth
        onSelectionChange={setSelectedTab}
        classNames={{
          tabContent:
            'text-[18px]  group-data-[selected=true]:text-persianIndigo font-main font-normal text-persianIndigo',
          tabList: 'gap-0 border-b-1 border-thistle pb-[30px]',
          cursor: 'w-[102%] bottom-[-30px] bg-persianIndigo',
        }}>
        <Tab key="account" title={t('account')}>
          <AccountTab formik={formik} isUpdatingUserInfo={isUpdatingUserInfo} />
        </Tab>
        <Tab key="cards" title={t('my_cards')}>
          <CardsTab />
        </Tab>
        <Tab key="orders" title={t('order_history')}>
          <OrdersTab />
        </Tab>
        <Tab key="addresses" title={t('addresses')}>
          <AddressesTab />
        </Tab>
        <Tab key="favorites" title={t('favorites')}>
          <FavoritesTab />
        </Tab>
      </Tabs>
    </div>
  );
};

export default ProfileTabs;
