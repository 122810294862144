'use client';

import {
  commonDisableCachingParameters,
  convertEnglishNumbersToArabic,
} from '@/common';
import {useGetOrdersQuery} from '@/services';
import startCase from 'lodash/startCase';
import moment from 'moment';
import {useLocale, useTranslations} from 'next-intl';
import {Fragment, useState} from 'react';
import {OrderDetailsModal} from '.';
import {CancelOrderModal} from '../Checkout';

const OrdersTab = () => {
  const t = useTranslations();
  const locale = useLocale();
  const [isOpen, setIsOpen] = useState(false);
  const [isCancellationModalOpen, setIsCancellationModalOpen] = useState(false);
  const [orderNumber, setOrderNumber] = useState(null);
  const [order, setOrder] = useState(null);
  const {
    data: ordersResponse,
    error: getOrderError,
    isLoading: getOrderLoading,
  } = useGetOrdersQuery(
    {
      include: 'shipping_address,line_items',
      sort: '-completed_at',
      locale,
    },
    {
      pollingInterval: 3000,
      ...commonDisableCachingParameters,
    },
  );

  const handleOpenModal = (number, order) => {
    if (number) {
      setOrderNumber(number);
      setIsOpen(true);
    }
    if (order) {
      setOrder(order);
    }
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setOrderNumber(null);
    setOrder(null);
  };

  const handleOpenCancellationModal = () => {
    setIsCancellationModalOpen(true);
    setIsOpen(false);
  };

  return (
    <div className="container mt-8 md:mx-auto md:px-4">
      <table className="w-full border-collapse">
        <thead>
          <tr>
            <th className="p-1 text-center font-main text-[12px] font-normal text-persianIndigo sm:text-[14px] md:p-4 xl:text-[18px]">
              {t('order_number')}
            </th>
            <th className="p-1 text-center font-main text-[12px] font-normal text-persianIndigo sm:text-[14px] md:p-4 xl:text-[18px]">
              {t('date')}
            </th>
            <th className="p-1 text-center font-main text-[12px] font-normal text-persianIndigo sm:text-[14px] md:p-4 xl:text-[18px]">
              {t('order_status')}
            </th>
            <th className="p-1 text-center font-main text-[12px] font-normal text-persianIndigo sm:text-[14px] md:p-4 xl:text-[18px]">
              {t('items')}
            </th>
            <th className="p-1 text-center font-main text-[12px] font-normal text-persianIndigo sm:text-[14px] md:p-4 xl:text-[18px]">
              {t('total')}
            </th>
          </tr>
        </thead>
        <tbody className="w-full space-y-4">
          {ordersResponse?.orders.length > 0 ? (
            ordersResponse.orders.map(order => (
              <Fragment key={order.id}>
                <tr className="h-4" />
                <tr
                  className="cursor-pointer border border-thistle"
                  onClick={() =>
                    handleOpenModal(order.attributes.number, order)
                  }>
                  <td className="p-1 text-center font-secondary text-[12px] font-normal text-persianIndigo sm:text-[16px] md:p-4">
                    {order.attributes.number}
                  </td>
                  <td className="p-1 text-center font-secondary text-[12px] font-normal text-persianIndigo sm:text-[16px] md:p-4">
                    {moment(order.attributes.completed_at)
                      .locale(locale)
                      .format('DD.MM.YYYY')}
                  </td>
                  <td className="p-1 text-center font-secondary text-[12px] font-normal text-persianIndigo sm:text-[16px] md:p-4">
                    {startCase(order.attributes.shipment_state)}
                  </td>
                  <td className="max-w-[5rem] overflow-hidden text-ellipsis whitespace-nowrap p-1 text-center font-secondary text-[12px] font-normal text-persianIndigo sm:text-[16px] md:max-w-xs md:p-4">
                    {order.lineItems?.map(item => item.name).join(', ')}
                  </td>
                  <td className="p-1 text-center font-secondary text-[12px] font-normal text-persianIndigo sm:text-[16px] md:p-4">
                    {convertEnglishNumbersToArabic(
                      Number(order.attributes.total),
                      locale,
                    )}{' '}
                    {t('sar')}
                  </td>
                </tr>
              </Fragment>
            ))
          ) : (
            <tr>
              <td
                colSpan="5"
                className="pt-[7rem] text-center font-main text-[12px] font-normal text-persianIndigo sm:text-[16px]">
                {t('no_orders')}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {orderNumber && (
        <OrderDetailsModal
          isOpen={isOpen}
          onClose={handleCloseModal}
          t={t}
          orderNumber={orderNumber}
          handleOpenCancellationModal={handleOpenCancellationModal}
        />
      )}
      <CancelOrderModal
        isOrderCancellationModalOpen={isCancellationModalOpen}
        setIsOrderCancellationModalOpen={setIsCancellationModalOpen}
        order={order?.attributes}
      />
    </div>
  );
};

export default OrdersTab;
