'use client';
import {CustomButton} from '@/components/common';
import useEmblaCarousel from 'embla-carousel-react';
import {useLocale, useTranslations} from 'next-intl';
import Image from 'next/image';
import React, {useState, useEffect} from 'react';
import {MdKeyboardArrowRight, MdKeyboardArrowLeft} from 'react-icons/md';

const QuickViewCarousal = ({selectedProduct}) => {
  const locale = useLocale();
  const t = useTranslations();
  const [emblaRef, emblaApi] = useEmblaCarousel({loop: false});
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [canScrollNext, setCanScrollNext] = useState(false);
  const [canScrollPrev, setCanScrollPrev] = useState(false);

  const onThumbnailClick = index => setSelectedImageIndex(index);

  const getImages = () => {
    if (selectedProduct?.variantImages?.length > 0) {
      return selectedProduct?.variantImages;
    }
    if (selectedProduct?.mergedImages?.length > 0) {
      return selectedProduct?.mergedImages.map(
        img => img.attributes.original_url,
      );
    }
    return [];
  };

  const images = getImages();

  const goNext = () => {
    if (emblaApi) {
      emblaApi.scrollNext();
      setSelectedImageIndex(prev => Math.min(prev + 1, images.length - 1));
    }
  };

  const goPrevious = () => {
    if (emblaApi) {
      emblaApi.scrollPrev();
      setSelectedImageIndex(prev => Math.max(prev - 1, 0));
    }
  };

  useEffect(() => {
    if (emblaApi) {
      const updateScrollButtons = () => {
        setCanScrollNext(emblaApi.canScrollNext());
        setCanScrollPrev(emblaApi.canScrollPrev());
      };

      emblaApi.on('select', updateScrollButtons);
      emblaApi.on('reInit', updateScrollButtons);

      updateScrollButtons();

      return () => {
        emblaApi.off('select', updateScrollButtons);
        emblaApi.off('reInit', updateScrollButtons);
      };
    }
  }, [emblaApi]);

  const renderImageGallery = images => (
    <>
      <div className="h-[12.5rem] w-full sm:h-[25rem] sm:w-[23.125rem]">
        <Image
          src={images[selectedImageIndex]?.originalUrl}
          width={525}
          height={425}
          className="h-[98%] rounded-[8px] object-fill sm:h-[24.375rem] sm:w-[23.125rem]"
          alt="thumbnail"
          dangerouslyallowsvg="true"
        />
      </div>
      <div className="flex items-center justify-between gap-2 sm:w-[25.8rem] sm:gap-[16px] sm:px-0">
        {canScrollPrev && (
          <CustomButton
            iconOnly
            value={
              locale === 'ar' ? (
                <MdKeyboardArrowRight />
              ) : (
                <MdKeyboardArrowLeft />
              )
            }
            onClickHandling={locale === 'ar' ? goNext : goPrevious}
            btnStyles="z-40 cursor-pointer bg-thistle p-[0.25rem] w-[1.5rem] h-[1.5rem] !min-w-0 sm:!min-w-[2rem] sm:w-[2rem] sm:h-[2rem] text-[1.5rem]"
          />
        )}
        <div className="embla mx-auto w-[90%] overflow-hidden" ref={emblaRef}>
          <div
            className={`${
              locale === 'ar' ? 'flex-row-reverse' : 'flex-row'
            } embla__container flex ${!canScrollNext && !canScrollPrev ? 'justify-center' : ''} gap-[10px]`}>
            {images.map((image, index) => (
              <div className="embla__slide" key={index}>
                <div
                  className={`flex h-[5rem] w-[7rem] cursor-pointer items-center justify-center rounded-[8px] sm:h-[7.6rem] sm:w-[7.3rem] ${
                    selectedImageIndex === index
                      ? 'border-2 border-royalPurple'
                      : ''
                  }`}
                  onClick={() => onThumbnailClick(index)}>
                  <Image
                    src={image.originalUrl}
                    width={300}
                    height={300}
                    className="h-full w-full rounded-[8px] object-fill sm:h-[7.188rem] sm:w-[6.875rem]"
                    alt="product view"
                    priority={true}
                    dangerouslyallowsvg="true"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        {canScrollNext && (
          <CustomButton
            iconOnly
            value={
              locale === 'ar' ? (
                <MdKeyboardArrowLeft />
              ) : (
                <MdKeyboardArrowRight />
              )
            }
            onClickHandling={locale === 'ar' ? goPrevious : goNext}
            btnStyles="z-40 cursor-pointer bg-thistle p-[0.25rem] w-[1.5rem] h-[1.5rem] !min-w-0 sm:!min-w-[2rem] sm:w-[2rem] sm:h-[2rem] text-[1.5rem]"
          />
        )}
      </div>
    </>
  );

  return (
    <div
      className={`flex flex-col ${
        selectedProduct?.variantImages?.length > 0 ||
        selectedProduct?.mergedImages?.length > 0
          ? 'justify-between sm:items-center'
          : 'items-center justify-center'
      }`}>
      {selectedProduct?.variantImages?.length > 0 ? (
        renderImageGallery(selectedProduct.variantImages)
      ) : selectedProduct?.mergedImages?.length > 0 ? (
        renderImageGallery(selectedProduct.mergedImages)
      ) : (
        <div className="font-secondary">{t('no_images_for_this_product')}</div>
      )}
    </div>
  );
};

export default QuickViewCarousal;
