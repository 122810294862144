'use client';
import {eventsHome, flowersSub, weekly} from '@/assets';
import {useLocale, useTranslations} from 'next-intl';
import Image from 'next/image';
import React from 'react';
import {CustomButton} from '../common';
import {useMediaQuery} from 'react-responsive';
import {UseHandleControlledSwipe} from '@/hooks';
import Link from 'next/link';

const HomeNewIdeasSection = () => {
  const t = useTranslations();
  const isMobile = useMediaQuery({query: '(max-width: 640px)'});
  const locale = useLocale();

  const {emblaRef} = UseHandleControlledSwipe(3);

  return (
    <div className="my-[3rem] ms-7 sm:ms-0 sm:px-[9.17vw]">
      <p className="mb-[2vw] font-bentogaThin text-[20px] font-thin text-spanishViolet sm:mb-16 sm:text-[clamp(16px,2.86vw,55px)]">
        {t('discover_new_ideas')}
      </p>
      <div
        className="me-4 overflow-hidden sm:me-0"
        ref={isMobile ? emblaRef : null}>
        <div className="flex gap-4 sm:grid sm:grid-cols-2 sm:grid-rows-4">
          <div className="relative min-w-[80%] sm:col-span-1 sm:row-span-4 sm:min-w-[50%]">
            <Image
              src={weekly}
              alt="Express Delivery"
              width={600}
              height={400}
              placeholder="blur"
              priority
              className="w-full rounded-[8px] object-cover sm:h-full"
            />
            <div className="sm:rounded-b-0 absolute bottom-0 flex w-full items-center justify-between rounded-b-[8px] rounded-t-[16px] bg-nileBlue bg-opacity-80 p-8 text-white sm:border-b-[8px]">
              <h3 className="font-secondary text-[14px] font-semibold sm:text-[20px]">
                {t('express_delivery')}
              </h3>
              <Link href={`/${locale}/contact`}>
                <CustomButton
                  btnType="secondary"
                  btnStyles="w-[155px] bg-persianIndigo !border-none font-thin text-[12px] sm:text-[16px] !rounded-[40px] font-secondary !text-white"
                  value={t('gifts_now')}
                />
              </Link>
            </div>
          </div>

          <div className="relative min-w-[80%] sm:row-span-2 sm:min-w-[50%]">
            <Image
              src={flowersSub}
              alt="Weekly Flowers Subscription"
              width={600}
              height={400}
              placeholder="blur"
              priority
              className="h-full w-full rounded-[8px] object-cover"
            />
            <div className="sm:rounded-b-0 absolute bottom-0 flex w-full items-center justify-between rounded-b-[8px] rounded-t-[16px] bg-nileBlue bg-opacity-80 p-8 text-white sm:border-b-[8px]">
              <h3 className="font-secondary text-[14px] font-semibold sm:text-[20px]">
                {t('weekly_flowers_subscription')}
              </h3>
              <Link href={`/${locale}/contact`}>
                <CustomButton
                  btnType="secondary"
                  btnStyles="w-[155px] bg-persianIndigo !border-none font-thin text-[12px] sm:text-[16px] !rounded-[40px] font-secondary !text-white"
                  value={t('subscribe_now')}
                />
              </Link>
            </div>
          </div>

          <div className="relative min-w-[80%] sm:row-span-2 sm:min-w-[50%]">
            <Image
              src={eventsHome}
              alt="Events"
              width={600}
              height={400}
              placeholder="blur"
              priority
              className="h-full w-full rounded-[8px] object-cover"
            />
            <div className="sm:rounded-b-0 absolute bottom-0 flex w-full items-center justify-between rounded-b-[8px] rounded-t-[16px] bg-nileBlue bg-opacity-80 p-8 text-white sm:border-b-[8px]">
              <h3 className="font-secondary text-[14px] font-semibold sm:text-[20px]">
                {t('events')}
              </h3>
              <Link href={`/${locale}/contact`}>
                <CustomButton
                  btnType="secondary"
                  btnStyles="w-[155px] bg-persianIndigo text-[12px] sm:text-[16px] !border-none font-thin !rounded-[40px] font-secondary !text-white"
                  value={t('arrange_now')}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeNewIdeasSection;
