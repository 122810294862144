'use client';
import {
  convertEnglishNumbersToArabic,
  showErrorToast,
  showSuccessToast,
} from '@/common';
import {useRouter} from '@/hooks';
import {
  getIsOTPModalOpen,
  getIsResetPasswordModalOpen,
  getToken,
} from '@/selectors';
import {
  useLogoutMutation,
  useRequestUserOtpMutation,
  useVerifyUserOtpMutation,
} from '@/services';
import {
  closeOtpModal,
  openOtpModal,
  openResetPasswordModal,
  resetCarts,
} from '@/slices';
import {useLocale, useTranslations} from 'next-intl';
import Image from 'next/image';
import {useEffect, useRef, useState} from 'react';
import {FaRegEdit} from 'react-icons/fa';
import {RiLogoutBoxRLine} from 'react-icons/ri';
import {useDispatch, useSelector} from 'react-redux';
import {useMediaQuery} from 'react-responsive';
import {OtpModal} from '../Auth';
import {CustomButton} from '../common';
import ResetPasswordModal from './ResetPasswordModal';

const UserInfo = ({
  formik,
  userBalance,
  userName,
  isUserVerified,
  userPhoneNumber,
}) => {
  const t = useTranslations();
  const [profileImage, setProfileImage] = useState(null);
  const fileInputRef = useRef(null);
  const [logout, {isLoading: isLogoutLoading}] = useLogoutMutation();
  const [requestUserOtp] = useRequestUserOtpMutation();
  const [verifyUserOtp] = useVerifyUserOtpMutation();

  const router = useRouter();
  const token = useSelector(getToken);
  const dispatch = useDispatch();
  const isResetPasswordModalOpen = useSelector(getIsResetPasswordModalOpen);
  const isMobile = useMediaQuery({query: '(max-width: 640px)'});
  const isOtpModalOpen = useSelector(getIsOTPModalOpen);
  const locale = useLocale();
  const onHandlingVerifyingAccount = async () => {
    const response = await requestUserOtp({
      username: userPhoneNumber,
    });
    if (response?.error) {
      showErrorToast(response.error.error);
    } else {
      dispatch(openOtpModal());
    }
  };

  const onVerificationSuccess = () => {
    dispatch(closeOtpModal());
    showSuccessToast(t('account_verified_successfully'));
  };

  const handleImageChange = e => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
        formik.setFieldValue('image', reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onOpeningResetPasswordUpdateModal = () => {
    dispatch(openResetPasswordModal());
  };

  const convertImageUrlToBase64 = async imageUrl => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const onHandlingLogout = async () => {
    const response = await logout({
      access_token: token,
    });
    if (!response.error) {
      router.push('/');
      dispatch(resetCarts());
    } else {
      showErrorToast(response.error);
    }
  };

  useEffect(() => {
    const initializeProfileImage = async () => {
      if (formik.values.image && !formik.values.image.startsWith('data:')) {
        const base64Image = await convertImageUrlToBase64(formik.values.image);
        setProfileImage(base64Image);
        formik.setFieldValue('image', base64Image);
      }
    };

    initializeProfileImage();
  }, [formik.values.image]);
  return isMobile ? (
    <>
      <p className="ps-[4.5rem] pt-[2rem] font-main text-[12px] font-bold text-persianIndigo">
        {t('account_profile')}
      </p>
      <div className="mx-auto my-4 flex w-[75%] flex-col items-center rounded-lg border border-thistle bg-white p-6 sm:w-[90%]">
        <div className="cursor-pointer self-end">
          <FaRegEdit
            className="text-thistle"
            size={20}
            onClick={() => fileInputRef.current.click()}
          />
          <input
            ref={fileInputRef}
            type="file"
            name="image"
            accept="image/*"
            className="hidden"
            onChange={handleImageChange}
          />
        </div>
        <div className="flex w-full items-center gap-8">
          <div className="mb-5 h-[100px] w-[100px] overflow-hidden rounded-full bg-lightGray">
            {formik.values.image || profileImage ? (
              <Image
                src={profileImage ? profileImage : formik.values.image}
                alt="Profile"
                className="h-full w-full object-cover"
                width={100}
                height={100}
              />
            ) : (
              <div className="h-full w-full bg-lightGray"></div>
            )}
          </div>
          <div className="grow">
            <h3 className="mb-3 font-main text-[20px] font-bold text-persianIndigo">
              {userName}
            </h3>
          </div>
        </div>
        <div className="mb-4 flex w-full justify-between font-secondary text-[12px] text-persianIndigo">
          <span>{t('wallet_balance')}</span>
          <span className="font-bold">
            {convertEnglishNumbersToArabic(userBalance || 0, locale)} {t('sar')}
          </span>
        </div>
        <hr className="mb-2 w-full border-thistle" />
        <div className="mb-2 flex w-full justify-between font-secondary text-[12px] text-persianIndigo">
          <CustomButton
            value={t('password_manager')}
            btnType="tertiary"
            btnStyles="font-secondary !text-[12px] text-persianIndigo underline !p-0 hover:!bg-transparent"
            onClickHandling={onOpeningResetPasswordUpdateModal}
          />
        </div>
        <hr className="mb-2 w-full border-thistle" />
        {!isUserVerified && (
          <div className="mb-2 flex w-full justify-between font-secondary text-[12px] text-persianIndigo">
            <CustomButton
              value={t('verify_your_account')}
              btnType="tertiary"
              btnStyles="font-secondary !text-[12px] text-persianIndigo underline !p-0 hover:!bg-transparent"
              onClickHandling={onHandlingVerifyingAccount}
            />
          </div>
        )}
        <CustomButton
          btnType="tertiary"
          onClickHandling={onHandlingLogout}
          btnStyles="p-0 self-start"
          value={
            <div className="flex items-center justify-start gap-[2.5rem] border-thistle">
              <RiLogoutBoxRLine className="mb-[4px] cursor-pointer text-[20px] text-persianIndigo" />
              <span className="cursor-pointer font-secondary text-[18px] text-persianIndigo underline">
                {t('logout')}
              </span>
            </div>
          }
        />

        {isResetPasswordModalOpen && <ResetPasswordModal />}
        <OtpModal
          isOpen={isOtpModalOpen}
          userPhoneNumber={userPhoneNumber}
          onRequestOtp={() => requestUserOtp({username: userPhoneNumber})}
          onVerifyOtp={otp =>
            verifyUserOtp({username: userPhoneNumber, otp_code: otp})
          }
          onVerificationSuccess={onVerificationSuccess}
        />
      </div>
    </>
  ) : (
    <div className="flex flex-col bg-white p-[35px]">
      <div className="mb-5 cursor-pointer self-end">
        <FaRegEdit
          className="text-thistle"
          size={20}
          onClick={() => fileInputRef.current.click()}
        />
        <input
          ref={fileInputRef}
          type="file"
          name="image"
          accept="image/*"
          className="hidden"
          onChange={handleImageChange}
        />
      </div>
      <div className="mb-5 h-[147px] w-[147px] self-center overflow-hidden rounded-full bg-lightGray">
        {formik.values.image || profileImage ? (
          <Image
            src={
              profileImage
                ? profileImage
                : process.env.NEXT_PUBLIC_API_URL + formik.values.image
            }
            alt="Profile"
            className="h-full w-full object-cover"
            width={100}
            height={100}
          />
        ) : (
          <div className="h-full w-full bg-lightGray"></div>
        )}
      </div>
      <div className="self-center pb-5">
        <h3 className="font-main text-[25px] font-bold text-persianIndigo">
          {userName}
        </h3>
      </div>
      <div className="flex justify-between border-b-1 border-thistle py-4">
        <span className="font-secondary text-persianIndigo">
          {t('wallet_balance')}
        </span>
        <span className="font-secondary font-bold text-persianIndigo">
          {convertEnglishNumbersToArabic(userBalance || 0, locale)} {t('sar')}
        </span>
      </div>
      <div className="flex justify-between border-b-1 border-thistle py-3">
        <CustomButton
          value={t('password_manager')}
          btnType="tertiary"
          btnStyles="font-secondary text-persianIndigo underline !p-0 hover:!bg-transparent"
          onClickHandling={onOpeningResetPasswordUpdateModal}
        />
      </div>
      {!isUserVerified && (
        <div className="mb-3 flex justify-between border-b-1 border-thistle py-3">
          <CustomButton
            value={t('verify_your_account')}
            btnType="tertiary"
            btnStyles="font-secondary text-persianIndigo underline !p-0 hover:!bg-transparent"
            onClickHandling={onHandlingVerifyingAccount}
          />
        </div>
      )}

      <CustomButton
        btnType="tertiary"
        onClickHandling={onHandlingLogout}
        btnStyles="justify-start p-0"
        value={
          <div className="flex items-center justify-start gap-[16px] border-thistle">
            <RiLogoutBoxRLine className="mb-[4px] cursor-pointer text-[20px] font-bold text-persianIndigo" />
            <span className="cursor-pointer font-secondary text-[18px] font-bold text-persianIndigo underline">
              {t('logout')}
            </span>
          </div>
        }
      />
      {isResetPasswordModalOpen && <ResetPasswordModal />}
      <OtpModal
        isOpen={isOtpModalOpen}
        userPhoneNumber={userPhoneNumber}
        onRequestOtp={() => requestUserOtp({username: userPhoneNumber})}
        onVerifyOtp={otp =>
          verifyUserOtp({username: userPhoneNumber, otp_code: otp})
        }
        onVerificationSuccess={onVerificationSuccess}
      />
    </div>
  );
};

export default UserInfo;
