'use client';

import {commonDisableCachingParameters} from '@/common';
import {getDeleteUserAddressModal} from '@/selectors';
import {useGetUserAddressesQuery} from '@/services';
import {openDeleteUserAddressModal} from '@/slices';
import {useLocale, useTranslations} from 'next-intl';
import {useState} from 'react';
import {FaRegEdit} from 'react-icons/fa';
import {FaRegTrashCan} from 'react-icons/fa6';
import {useDispatch, useSelector} from 'react-redux';
import {AddAddressModal, DeleteUserAddressModal, EditAddressModal} from '.';
import {CustomButton} from '../common';

const AddressesTab = () => {
  const t = useTranslations();
  const locale = useLocale();
  const [isAddAddressModalOpen, setIsAddAddressModalOpen] = useState(false);
  const [isEditAddressModalOpen, setIsEditAddressModalOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const {
    data: addresses,
    error: getAddressesError,
    isLoading: getAddressLoading,
  } = useGetUserAddressesQuery({locale}, commonDisableCachingParameters);
  const isDeleteUserAddressModalOpen = useSelector(getDeleteUserAddressModal);
  const dispatch = useDispatch();

  const handleOpenEditAddressModal = address => {
    setSelectedAddress(address);
    setIsEditAddressModalOpen(true);
  };

  const handleCloseEditAddressModal = () => {
    setIsEditAddressModalOpen(false);
  };

  const handleCloseAddAddressModal = () => {
    setIsAddAddressModalOpen(false);
  };
  const handleOpeningDeleteUserAddressModal = address => {
    setSelectedAddress(address);
    dispatch(openDeleteUserAddressModal());
  };

  return (
    <div>
      {addresses?.data.length > 0 ? (
        <div className="grid grid-cols-1 gap-[25px] xl:grid-cols-2">
          {addresses.data.map(address => {
            const {id, attributes} = address;
            const {
              firstname,
              lastname,
              address1,
              address2,
              city,
              national_address,
            } = attributes;

            return (
              <div
                key={id}
                className="relative rounded-[5px] border border-thistle p-5 shadow">
                <FaRegTrashCan
                  className={`${locale === 'en' ? 'right-10' : 'left-10'} absolute top-2 cursor-pointer text-xl text-thistle`}
                  onClick={() => handleOpeningDeleteUserAddressModal(address)}
                />
                <FaRegEdit
                  className={`${locale === 'en' ? 'right-2' : 'left-2'} absolute top-2 cursor-pointer text-xl text-thistle`}
                  onClick={() => handleOpenEditAddressModal(address)}
                />

                <h3 className="font-main text-[20px] font-bold text-persianIndigo">{`${firstname}`}</h3>
                {address1 && (
                  <p className="font-secondary text-[16px] font-normal text-persianIndigo">
                    {address1}
                  </p>
                )}
                {address2 && (
                  <p className="font-secondary text-[16px] font-normal text-persianIndigo">
                    {address2}
                  </p>
                )}
                {national_address && (
                  <p className="font-secondary text-[16px] font-normal text-persianIndigo">
                    {national_address}
                  </p>
                )}
                <p className="font-secondary text-[16px] font-normal text-persianIndigo">
                  {t('ksa')}
                </p>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="flex h-[20rem] justify-center font-secondary text-[16px] text-persianIndigo">
          <p>{t('no_addresses')}</p>
        </div>
      )}
      <EditAddressModal
        isOpen={isEditAddressModalOpen}
        onClose={handleCloseEditAddressModal}
        t={t}
        address={selectedAddress}
      />
      <AddAddressModal
        isOpen={isAddAddressModalOpen}
        onClose={handleCloseAddAddressModal}
        t={t}
      />
      {isDeleteUserAddressModalOpen && (
        <DeleteUserAddressModal address={selectedAddress} />
      )}

      <div className="mt-12 flex w-full flex-row justify-end">
        <CustomButton
          btnStyles={'w-[192px]'}
          value={t('add_new')}
          onClickHandling={() => setIsAddAddressModalOpen(true)}
        />
      </div>
    </div>
  );
};

export default AddressesTab;
