'use client';
import {cities} from '@/common';
import {
  DatePicker,
  Input,
  Radio,
  RadioGroup,
  Select,
  SelectItem,
} from '@heroui/react';
import {getLocalTimeZone, parseDate, today} from '@internationalized/date';
import moment from 'moment';
import {useLocale, useTranslations} from 'next-intl';
import {useEffect, useState} from 'react';
import {SlCalender} from 'react-icons/sl';
import {CustomButton} from '../common';

const AccountTab = ({formik, isUpdatingUserInfo, isUserDataLoading}) => {
  const t = useTranslations();
  const locale = useLocale();

  const [value, setValue] = useState(null);
  const onHandlingDateOfBirthChange = newValue => {
    if (newValue) {
      const date = new Date(newValue.year, newValue.month - 1, newValue.day);
      formik.setFieldValue(
        'dateOfBirth',
        moment(date).locale('en').format('YYYY-MM-DD'),
      );
    }
  };

  useEffect(() => {
    if (formik.values.dateOfBirth) {
      setValue(parseDate(formik.values.dateOfBirth));
    }
  }, [formik.values.dateOfBirth]);

  const onHandlingSelectingGender = event => {
    formik.setFieldValue('gender', event.target.value);
  };

  return (
    <div>
      <div className="mt-[1.875rem] grid grid-cols-1 sm:gap-6 md:h-[20rem] md:grid-cols-2 xl:gap-[10rem]">
        <div>
          <div className="flex w-full flex-col gap-4 md:flex-wrap md:gap-2 xl:gap-4">
            <div>
              <Input
                type="text"
                name="firstName"
                label={t('name')}
                variant="bordered"
                labelPlacement="outside-left"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                radius="none"
                classNames={{
                  base: 'flex flex-col items-start',
                  mainWrapper: 'w-full',
                  input:
                    'py-4 text-[16px] text-persianIndigo font-secondary font-normal',
                  label:
                    'text-[16px] font-secondary text-persianIndigo font-normal mb-[10px]',
                  inputWrapper: [
                    '!border-1',
                    '!border-thistle',
                    'focus:border-1',
                    'focus:border-thistle',
                    '.group[data-focus=true] .group-data-[focus=true]:border-thistle',
                    'mb-[0.688rem]',
                    'rounded-[0.313rem]',
                  ],
                }}
              />
              {formik.touched.firstName && formik.errors.firstName ? (
                <div className="mt-2 font-secondary text-[12px] text-red-500">
                  {formik.errors.firstName}
                </div>
              ) : null}
            </div>
            <div>
              <Input
                type="text"
                name="phoneNumber"
                label={t('phone_number')}
                variant="bordered"
                labelPlacement="outside-left"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                startContent={
                  <>
                    <span className="font-secondary text-persianIndigo">
                      +966
                    </span>
                    <div className="mx-2 h-7 border-l border-thistle"></div>
                  </>
                }
                classNames={{
                  base: 'flex flex-col items-start',
                  mainWrapper: 'w-full',
                  input:
                    'py-4 !ps-0	text-[16px] text-persianIndigo font-secondary font-normal',
                  label:
                    'text-[16px] font-secondary text-persianIndigo font-normal mb-[10px]',
                  inputWrapper: [
                    '!border-1',
                    '!border-thistle',
                    'focus:border-1',
                    'focus:border-thistle',
                    '.group[data-focus=true] .group-data-[focus=true]:border-thistle',
                    'mb-[0.688rem]',
                    'rounded-[0.313rem]',
                  ],
                }}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                <div className="mt-2 font-secondary text-[12px] text-red-500">
                  {formik.errors.phoneNumber}
                </div>
              ) : null}
            </div>
            <div>
              <DatePicker
                name="dateOfBirth"
                showMonthAndYearPickers
                variant="bordered"
                labelPlacement="outside-left"
                radius="none"
                label={t('date_of_birth')}
                value={value}
                selectorIcon={
                  <SlCalender className="mb-4 text-persianIndigo" />
                }
                onChange={onHandlingDateOfBirthChange}
                maxValue={today(getLocalTimeZone()).subtract({years: 18})}
                onBlur={formik.handleBlur}
                dateInputClassNames={{
                  base: 'flex flex-col items-start',
                  mainWrapper: 'w-full',
                  input:
                    'py-4 text-[16px] text-persianIndigo font-secondary font-normal',
                  label:
                    'text-[16px] font-secondary text-persianIndigo font-normal mb-[10px]',
                  inputWrapper: [
                    '!border-1',
                    '!border-thistle',
                    'focus:border-1',
                    'focus:border-thistle',
                    '.group[data-focus=true] .group-data-[focus=true]:border-thistle',
                    'mb-[0.688rem]',
                    'rounded-[0.313rem]',
                  ],
                  innerWrapper: 'h-[3.5rem]',
                }}
                classNames={{
                  selectorButton: 'text-persianIndigo',
                }}
              />
              {formik.touched.dateOfBirth && formik.errors.dateOfBirth ? (
                <div
                  className={`${locale === 'ar' ? 'mr-3' : 'ml-3'} mt-2 font-secondary text-[12px] text-red-500`}>
                  {formik.errors.dateOfBirth}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div>
          <div className="flex w-full flex-col gap-4 md:flex-wrap md:gap-2 xl:gap-4">
            <div>
              <Input
                type="text"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={t('email')}
                variant="bordered"
                labelPlacement="outside-left"
                radius="none"
                classNames={{
                  base: 'flex flex-col items-start',
                  mainWrapper: 'w-full',
                  input:
                    'py-4 text-[16px] text-persianIndigo font-secondary font-normal',
                  label:
                    'text-[16px] font-secondary text-persianIndigo font-normal mb-[10px]',
                  inputWrapper: [
                    '!border-1',
                    '!border-thistle',
                    'focus:border-1',
                    'focus:border-thistle',
                    '.group[data-focus=true] .group-data-[focus=true]:border-thistle',
                    'mb-[0.688rem]',
                    'rounded-[0.313rem]',
                  ],
                }}
              />
              {formik.touched.email && formik.errors.email ? (
                <div
                  className={`${locale === 'ar' ? 'mr-3' : 'ml-3'} mt-2 font-secondary text-[12px] text-red-500`}>
                  {formik.errors.email}
                </div>
              ) : null}
            </div>
            <div>
              <Select
                label={t('city')}
                value={[formik.values.city]}
                selectedKeys={[formik.values.city]}
                onChange={formik.handleChange}
                defaultSelectedKeys={[formik.values.city]}
                onBlur={formik.handleBlur}
                name="city"
                items={cities}
                variant="bordered"
                labelPlacement="outside-left"
                radius="none"
                placeholder={t('select_your_city')}
                classNames={{
                  base: 'flex flex-col items-start',
                  mainWrapper: 'w-full',
                  value:
                    'py-8 text-[16px] !text-persianIndigo font-secondary font-normal',
                  label:
                    'text-[16px] font-secondary text-persianIndigo font-normal mb-[10px]',
                  trigger: [
                    'h-[3.188rem]',
                    '!border-1',
                    '!border-thistle',
                    'focus:border-1',
                    'rounded-[0.313rem]',
                    'focus:border-thistle',
                    '.group[data-focus=true] .group-data-[focus=true]:border-thistle',
                  ],
                  selectorIcon: 'text-persianIndigo',
                }}>
                {city => (
                  <SelectItem
                    key={city.key}
                    classNames={{
                      title: 'text-persianIndigo',
                      selectedIcon: 'text-persianIndigo',
                    }}>
                    {locale === 'en' ? city.label : city.arLabel}
                  </SelectItem>
                )}
              </Select>

              {formik.touched.city && formik.errors.city ? (
                <div
                  className={`${locale === 'ar' ? 'mr-3' : 'ml-3'} mt-2 font-secondary text-[12px] text-red-500`}>
                  {formik.errors.city}
                </div>
              ) : null}
            </div>
            <div className="mt-16">
              <RadioGroup
                onChange={onHandlingSelectingGender}
                defaultValue={formik.values.gender}
                value={formik.values.gender}
                className="!flex-row !justify-between"
                classNames={{
                  label: 'font-secondary text-persianIndigo font-normal',
                  wrapper: 'flex flex-row gap-6',
                }}
                label={t('gender')}
                orientation="horizontal"
                color="secondary">
                <Radio value="male">
                  {' '}
                  <span className="text-persianIndigo">{t('male')}</span>
                </Radio>
                <Radio value="female">
                  <span className="text-persianIndigo">{t('female')}</span>
                </Radio>
              </RadioGroup>
              {formik.touched.gender && formik.errors.gender ? (
                <div className={`mt-2 font-secondary text-[12px] text-red-500`}>
                  {formik.errors.gender}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <CustomButton
          isDisabled={!formik.isValid}
          isLoading={isUpdatingUserInfo}
          btnStyles={'self-end w-[192px] mt-[20px]'}
          btnActionType="submit"
          onClickHandling={formik.handleSubmit}
          value={t('update')}
        />
      </div>
    </div>
  );
};

export default AccountTab;
