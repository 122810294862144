'use client';
import {useLocale, useTranslations} from 'next-intl';
import {IoMdClose} from 'react-icons/io';

const MobileCategoryList = ({
  categories,
  selectedCategory,
  handleCategoryClick,
  categoryId,
}) => {
  const t = useTranslations();
  const locale = useLocale();
  return (
    <div className="mx-[2.25rem] mt-4">
      <h2 className="font-semiBold mb-4 font-main text-persianIndigo sm:text-[18px] xl:text-[1vw]">
        {t('categories')}
      </h2>
      <p className="mb-6 font-secondary text-[14px] text-persianIndigo">
        {t('choose_the_category_you_would_like_to_see')}
      </p>
      <div className="mb-6 flex flex-wrap gap-2">
        <button
          className={`${selectedCategory === categoryId ? 'justify-between border-2 border-persianIndigo' : 'justify-center border-2 border-thistle'} flex !w-fit items-center gap-4 rounded-full px-4 py-1 text-[12px]`}
          onClick={() => handleCategoryClick(categoryId)}>
          <span className="flex items-center justify-between font-secondary text-persianIndigo">
            {t('all')}
          </span>
          {selectedCategory === categoryId && (
            <div className="relative top-[-1px] rounded-full border-1 border-persianIndigo">
              <IoMdClose
                size={12}
                className="font-secondary font-bold text-persianIndigo"
              />
            </div>
          )}
        </button>
        {categories?.map((item, index) => (
          <button
            key={index}
            className={`${selectedCategory === item.id ? 'justify-between border-2 border-persianIndigo' : 'justify-center border-2 border-thistle'} flex w-fit items-center gap-4 rounded-full px-4 py-1 text-[12px]`}
            onClick={() => handleCategoryClick(item.id)}>
            <span className="flex items-center justify-between font-secondary text-persianIndigo">
              {item.attributes.name}
            </span>
            {selectedCategory === item.id && (
              <div className="relative top-[-1px] rounded-full border-1 border-persianIndigo">
                <IoMdClose
                  size={12}
                  className="font-secondary font-bold text-persianIndigo"
                />
              </div>
            )}
          </button>
        ))}
      </div>

      <hr className="border-0 border-t border-thistle" />
    </div>
  );
};

export default MobileCategoryList;
