'use client';
import React, {useEffect, useState} from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import {MdKeyboardArrowLeft, MdKeyboardArrowRight} from 'react-icons/md';
import {motion} from 'framer-motion';
import {useLocale, useTranslations} from 'next-intl';
import {useDisclosure} from '@heroui/react';
import Image from 'next/image';
import {branch, product2} from '@/assets';
import {useGetAllBestSellingQuery} from '@/services';
import {CustomButton} from '../common';
import {convertEnglishNumbersToArabic, renderStars} from '@/common';
import {getNearestStoreData} from '@/selectors';
import {useSelector} from 'react-redux';
import ProductQuickView from './ProductQuickView';
import {GoHeart, GoHeartFill} from 'react-icons/go';
import {UseAddOrRemoveFav} from '@/hooks';
import Link from 'next/link';

const BestSellingSection = ({category}) => {
  const locale = useLocale();
  const store = useSelector(getNearestStoreData);
  const {
    applyOrRemoveFavItem,
    localFavData,
    isFavProductsAreLoading,
    favToken,
  } = UseAddOrRemoveFav();
  const {data: allProducts, error: getBestSellingError} =
    useGetAllBestSellingQuery(
      {locale, 'filter[branches]': store?.id, 'filter[taxons]': category?.id},
      {
        skip: !store?.id,
      },
    );
  const t = useTranslations();
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    direction: locale === 'ar' ? 'rtl' : 'ltr',
    dragFree: true,
    containScroll: 'trim',
    speed: 12,
  });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const {onOpen, isOpen, onOpenChange, onClose} = useDisclosure();
  const variants = {
    initial: {y: 75},
    animate: {y: 0},
  };

  useEffect(() => {
    if (!emblaApi) return;
    emblaApi.on('select', () =>
      setSelectedIndex(emblaApi.selectedScrollSnap()),
    );
  }, [emblaApi]);

  const onSavingSelectedProductState = product => {
    setSelectedProduct(product);
    onOpen();
  };
  const onHandlingFavoriteList = product => {
    const isProductInFavorites = localFavData?.find(
      item => item?.product.id === product?.id,
    );
    return isProductInFavorites;
  };

  const onHandlingFavActions = product => {
    applyOrRemoveFavItem(
      onHandlingFavoriteList(product),
      product?.variants[0]?.id,
      product?.id,
    );
  };

  if (allProducts?.products.length > 0) {
    return (
      <>
        <div className="mb-6 mt-[3rem] flex items-center justify-between pe-4 ps-8 sm:mb-10 sm:px-[3.75rem] xl:px-[9vw]">
          <div className="text-center">
            <p className="font-bentogaThin text-[20px] font-medium text-spanishViolet sm:text-[clamp(16px,2.86vw,55px)]">
              {t('our_top_seller_products')}
            </p>
          </div>
          <Link
            href={`/${locale}/category/${category?.attributes.permalink}/${category?.id}`}>
            <div className="flex cursor-pointer items-center gap-1">
              <h3 className="font-secondary font-normal text-persianIndigo underline sm:text-[clamp(16px,1.56vw,30px)]">
                {t('view_all')}
              </h3>
              {locale === 'ar' ? (
                <MdKeyboardArrowLeft className="text-[16px] text-persianIndigo sm:text-[24px]" />
              ) : (
                <MdKeyboardArrowRight className="text-[16px] text-persianIndigo sm:text-[24px]" />
              )}
            </div>
          </Link>
        </div>
        <div className="relative mb-[6.2vw] ms-7 sm:ms-0 sm:px-[9.17vw] rtl:text-right">
          <div className="w-full overflow-hidden" ref={emblaRef}>
            <div className="me-4 flex gap-4 sm:me-0 sm:gap-[1.8vw]">
              {allProducts?.products?.map(product => {
                if (!product.inStock) {
                  return null;
                }

                return (
                  <div className="flex-col" key={product.id}>
                    <div
                      className="group relative box-border h-[11.875rem] w-[37.44vw] sm:h-[21.8vw] sm:w-[20.8vw]"
                      suppressHydrationWarning={true}>
                      <motion.div
                        initial="initial"
                        animate="initial"
                        whileHover="animate"
                        className="relative flex h-[11.875rem] w-[37.44vw] cursor-pointer flex-col justify-end overflow-hidden sm:h-[21.8vw] sm:w-[20.8vw]">
                        <Link
                          href={`/${locale}/${category?.attributes?.permalink}/${product.slug}/${product.id}?sid=${store?.id}`}>
                          <Image
                            sizes="33vw"
                            fill
                            className="h-[11.875rem] w-[37.44vw] rounded-[16px] sm:h-[21.8vw] sm:w-[20.8vw]"
                            alt={product?.name}
                            src={
                              product?.images?.length > 0
                                ? product?.images[0]?.originalUrl
                                : product2
                            }
                          />
                        </Link>
                        {favToken && (
                          <div className="absolute top-0 xl:top-[10px] ltr:right-[4px] ltr:sm:right-[10px] rtl:left-[4px] rtl:sm:left-[10px]">
                            <CustomButton
                              iconOnly
                              isLoading={isFavProductsAreLoading}
                              btnStyles="!bg-transparent cursor-pointer"
                              onClickHandling={() =>
                                onHandlingFavActions(product)
                              }
                              value={
                                onHandlingFavoriteList(product) ? (
                                  <GoHeartFill className="text-[22px] font-bold text-persianIndigo" />
                                ) : (
                                  <GoHeart className="text-[22px] font-bold text-spanishViolet" />
                                )
                              }
                            />
                          </div>
                        )}
                        <motion.div>
                          <motion.h1
                            className="group-hover relative w-[37.44vw] rounded-b-[16px] bg-spanishViolet bg-opacity-50 py-3 text-center font-secondary text-[20px] font-thin text-white sm:w-[20.8vw]"
                            variants={variants}
                            onClick={() =>
                              onSavingSelectedProductState(product)
                            }>
                            {t('quick_view')}
                          </motion.h1>
                        </motion.div>
                      </motion.div>
                    </div>

                    <div className="mt-2 flex items-center justify-between">
                      <div>
                        <h3 className="font-secondary text-[14px] font-normal text-persianIndigo sm:text-[clamp(12px,0.83vw,16px)]">
                          {product?.name}
                        </h3>
                      </div>
                      {parseInt(product?.totalRating) !== 0 && (
                        <div className="mt-[2px] flex items-center">
                          <div className="flex">
                            {renderStars(product?.totalRating)}
                            <span className="font-secondary text-[clamp(12px,0.83vw,16px)] font-normal text-persianIndigo">
                              {product?.totalRating}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <p className="font-secondary text-[clamp(12px,0.83vw,16px)] font-normal text-persianIndigo">
                      {convertEnglishNumbersToArabic(product?.price, locale)}{' '}
                      {`${t('sar')}`}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {isOpen && (
          <ProductQuickView
            selectedProduct={selectedProduct}
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            onClose={onClose}
          />
        )}
      </>
    );
  } else {
    return (
      <div className="relative">
        <Image
          width={230}
          height={190}
          alt="branch"
          className="absolute left-[-3.125rem] top-[-1.625rem] z-[-1]"
          src={branch}
        />
      </div>
    );
  }
};
export default BestSellingSection;
