'use client';
import React from 'react';
import {
  ForgetPasswordModal,
  LogInModal,
  NewPasswordModal,
  OtpModal,
  SignUpModal,
} from '../Auth';
import {useDispatch, useSelector} from 'react-redux';
import {
  getIsSignUpIsOpen,
  getIsLogInOpen,
  getIsForgetPasswordModalOpen,
  getIsNewPasswordUpdateModalOpen,
  getIsGeneralOtpModalOpen,
  getUserPhoneNumber,
  getUserPassword,
  getGoogleToken,
  getFacebookToken,
} from '@/selectors';
import {
  closeGeneralOtpModal,
  closeOtpModal,
  setFacebookToken,
  setGoogleToken,
  setUserPassowrd,
  setUserPhone,
} from '@/slices';
import {
  useLoginMutation,
  useRequestUserOtpMutation,
  useVerifyUserOtpMutation,
} from '@/services';

const Modals = () => {
  const isSignUpModalOpen = useSelector(getIsSignUpIsOpen);
  const isOtpModalOpen = useSelector(getIsGeneralOtpModalOpen);
  const isLogInModalOpen = useSelector(getIsLogInOpen);
  const isForgetPasswordModalOpen = useSelector(getIsForgetPasswordModalOpen);
  const isNewPasswordModalOpen = useSelector(getIsNewPasswordUpdateModalOpen);
  const userPhoneNumber = useSelector(getUserPhoneNumber);
  const userPassword = useSelector(getUserPassword);
  const googleToken = useSelector(getGoogleToken);
  const facebookToken = useSelector(getFacebookToken);
  const dispatch = useDispatch();
  const [requestUserOtp] = useRequestUserOtpMutation();
  const [verifyUserOtp] = useVerifyUserOtpMutation();
  const [onLogin] = useLoginMutation();
  const onClosingOtpModal = () => {
    dispatch(closeGeneralOtpModal());
    dispatch(closeOtpModal());
  };

  const onHandlingLogin = async () => {
    if (!googleToken && !facebookToken) {
      const response = await onLogin({
        username: userPhoneNumber,
        password: userPassword,
        grant_type: 'password',
      });
      if (!response.error) {
        onClosingOtpModal();
        dispatch(setUserPassowrd(null));
        dispatch(setUserPhone(null));
      }
    } else {
      onClosingOtpModal();
      dispatch(setUserPhone(null));
      dispatch(setGoogleToken(null));
      dispatch(setFacebookToken(null));
    }
  };

  const getClass = isOpen => {
    return `modal ${isOpen ? 'open' : 'hidden'}`;
  };
  return (
    <div>
      <div className={getClass(isLogInModalOpen)}>
        <LogInModal />
      </div>
      <div className={getClass(isOtpModalOpen)}>
        <OtpModal
          isOpen={isOtpModalOpen}
          userPhoneNumber={userPhoneNumber}
          onRequestOtp={() => requestUserOtp({username: userPhoneNumber})}
          onVerifyOtp={otp =>
            verifyUserOtp({username: userPhoneNumber, otp_code: otp})
          }
          onVerificationSuccess={onHandlingLogin}
        />
      </div>
      <div className={getClass(isSignUpModalOpen)}>
        <SignUpModal />
      </div>
      <div className={getClass(isForgetPasswordModalOpen)}>
        <ForgetPasswordModal />
      </div>
      <div className={getClass(isNewPasswordModalOpen)}>
        <NewPasswordModal />
      </div>
    </div>
  );
};

export default Modals;
