'use client';
import React from 'react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@heroui/react';
import {useDispatch, useSelector} from 'react-redux';
import {getIsDeleteCreditCardModalOpen} from '@/selectors/modalActionsSelectors';
import {closeDeleteCreditCard, openDeleteCreditCard} from '@/slices';
import {useDeleteCreditCardMutation} from '@/services';
import {useTranslations} from 'next-intl';
import {CustomButton} from '../common';
import {showErrorToast} from '@/common';

const DeleteCreditCardModal = ({selectedCard}) => {
  const t = useTranslations();
  const IsDeleteCreditCardModalOpen = useSelector(
    getIsDeleteCreditCardModalOpen,
  );
  const dispatch = useDispatch();

  const [deleteCreditCard, {isLoading: isDeletingCreditCardLoading}] =
    useDeleteCreditCardMutation();

  const onClosingDeleteCreditCardModal = () => {
    dispatch(closeDeleteCreditCard());
  };
  const onOpeningDeleteCreditCardModal = () => {
    dispatch(openDeleteCreditCard());
  };

  const onHandlingDeletingCreditCard = async () => {
    const response = await deleteCreditCard(selectedCard?.id);
    if (response?.error) {
      showErrorToast(response?.error.error);
    } else {
      onClosingDeleteCreditCardModal();
    }
  };

  return (
    <>
      <Modal
        size="2xl"
        className="p-12"
        isOpen={IsDeleteCreditCardModalOpen}
        onOpenChange={onOpeningDeleteCreditCardModal}
        onClose={onClosingDeleteCreditCardModal}
        classNames={{
          closeButton:
            '!text-[22px] !text-persianIndigo hover:!bg-thistle font-main !p-0 !rounded-md mt-4 !hidden sm:block',
          wrapper: 'items-center',
        }}>
        <ModalContent>
          {onClose => (
            <>
              <ModalHeader className="flex justify-center">
                <span className="w-[260px] border-b-4 border-thistle pb-[10px] text-center font-main text-[26px] font-medium text-persianIndigo">
                  {t('delete_credit_card')}
                </span>
              </ModalHeader>
              <ModalBody>
                <p className="text-center font-secondary text-persianIndigo">
                  {t('are_you_sure_you_want_to_delete_this_credit_card')}
                </p>
              </ModalBody>
              <ModalFooter>
                <div className="flex w-full justify-between gap-6">
                  <CustomButton
                    btnStyles="w-full"
                    btnType="secondary"
                    value={t('cancel')}
                    onClickHandling={onClosingDeleteCreditCardModal}
                  />
                  <CustomButton
                    btnStyles="w-full"
                    isLoading={isDeletingCreditCardLoading}
                    value={t('delete')}
                    onClickHandling={onHandlingDeletingCreditCard}
                  />
                </div>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteCreditCardModal;
