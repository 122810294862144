'use client';

import {Modal, ModalContent, ModalHeader, ModalBody} from '@heroui/modal';
import {
  AdvancedMarker,
  APIProvider,
  ControlPosition,
  InfoWindow,
  Map,
  MapControl,
  Pin,
} from '@vis.gl/react-google-maps';
import GooglePlacesAutocomplete, {
  geocodeByLatLng,
} from 'react-google-places-autocomplete';
import {FaSearch} from 'react-icons/fa';
import {useLocale, useTranslations} from 'next-intl';
import {geocodeByPlaceId, getLatLng} from 'react-google-places-autocomplete';
import {useEffect, useState} from 'react';
import Image from 'next/image';
import {minus, plus} from '@/assets';
import {useDispatch, useSelector} from 'react-redux';
import {
  getCurrentUserCoords,
  getIsDeliveryMapModalOpen,
  getPrevBranchId,
} from '@/selectors';
import {
  closeDeliveryMapModal,
  resetCarts,
  setDeliveryMethodId,
  updateUserCoords,
} from '@/slices';
import {
  useGetPickupStoresQuery,
  useLazyGetNearestStoreQuery,
  useUpdateCheckoutMutation,
} from '@/services';
import {Button} from '@heroui/react';
import {CustomButton} from '../common';
import {useRouter} from '@/hooks';
import {FaMapMarkerAlt} from 'react-icons/fa';
import {showErrorToast} from '@/common';
import {BiCurrentLocation} from 'react-icons/bi';

const DeliveryMapModal = ({shoppingMethodId}) => {
  const t = useTranslations();
  const locale = useLocale();
  const router = useRouter();
  const isDeliveryModalMapOpen = useSelector(getIsDeliveryMapModalOpen);
  const userCoords = useSelector(getCurrentUserCoords);
  const prevBranchId = useSelector(getPrevBranchId);
  const [place, setPlace] = useState(null);
  const [zoom, setZoom] = useState(10);
  const [center, setCenter] = useState({
    lat: 24.7136,
    lng: 46.6753,
  });
  const [prevCenter, setPrevCenter] = useState({
    lat: 24.7136,
    lng: 46.6753,
  });
  const [selectedStore, setSelectedStore] = useState(null);
  const disptach = useDispatch();
  const [getNearestStore, {isLoading: isGettingNearestStoreIsLoading}] =
    useLazyGetNearestStoreQuery({locale});
  const {data: stores} = useGetPickupStoresQuery(
    {locale},
    {skip: shoppingMethodId !== 2},
  );
  const [updateShoppingMethod] = useUpdateCheckoutMutation();

  const handleZoomIn = () => {
    setZoom(prevZoom => prevZoom + 1);
  };

  const handleZoomOut = () => {
    setZoom(prevZoom => prevZoom - 1);
  };

  const handleChange = async selected => {
    if (selected) {
      setPlace(selected);
      const results = await geocodeByPlaceId(selected?.value?.place_id);
      const {lat, lng} = await getLatLng(results[0]);
      setCenter({
        lat: lat,
        lng: lng,
      });
    }
  };

  const onClosingDeliveryMapModal = () => {
    disptach(closeDeliveryMapModal());
    setPlace(null);
    setCenter(prevCenter);
  };

  const onHandlingPickingLocation = async () => {
    const response = await getNearestStore({
      latitude: center.lat,
      longitude: center.lng,
      shopping_method_id: Number(shoppingMethodId),
      locale,
    });
    if (response?.error) {
      setCenter(prevCenter);
      showErrorToast(response.error.error);
    } else if (!response?.data?.id) {
      await getNearestStore({
        latitude: prevCenter.lat,
        longitude: prevCenter.lng,
        shopping_method_id: Number(shoppingMethodId),
        locale,
      });
      setCenter(prevCenter);
      showErrorToast(t('out_of_zone'));
      disptach(closeDeliveryMapModal());
    } else {
      disptach(
        updateUserCoords({
          userLat: center.lat,
          userLng: center.lng,
        }),
      );
      setPrevCenter(center);
      disptach(setDeliveryMethodId(Number(shoppingMethodId)));
      if (response?.data?.id === prevBranchId) {
        const response = await updateShoppingMethod({
          order: {
            shopping_method_id: Number(shoppingMethodId),
          },
        });
        if (response?.error) {
          showErrorToast(response.error.error);
        }
      } else {
        disptach(resetCarts());
        router.replace('/');
      }
      disptach(closeDeliveryMapModal());
      setPlace(null);
    }
  };

  const CustomZoomControls = ({onZoomIn, onZoomOut}) => {
    return (
      <div className="absolute bottom-[12px] right-[18px] z-[1]">
        <button
          onClick={onZoomIn}
          className={`${locale === 'ar' ? 'ml-2' : 'mr-2'}`}>
          <Image className="h-[20px] w-[20px]" src={plus} alt="plus" />
        </button>
        <button onClick={onZoomOut}>
          <Image src={minus} className="h-[20px] w-[20px]" alt="minus" />
        </button>
      </div>
    );
  };

  useEffect(() => {
    if (shoppingMethodId === 1) {
      setZoom(16);
    } else setZoom(11);
  }, [shoppingMethodId]);

  useEffect(() => {
    if (userCoords?.userLat && userCoords?.userLng) {
      setCenter({lat: userCoords?.userLat, lng: userCoords?.userLng});
    }
  }, [userCoords]);

  const handlePickStore = async store => {
    setSelectedStore(store);
    const storeAddress = await geocodeByLatLng({
      lat: store.latitude,
      lng: store.longitude,
    });
    if (storeAddress && storeAddress.length > 0) {
      const address = storeAddress.find(
        address =>
          address.types.includes('postal_code') ||
          address.types.includes('locality') ||
          address.types.includes('political'),
      );
      setPlace({
        label: address?.formatted_address,
      });
    }
  };

  const handleMarkerDragging = async (lat, lng) => {
    setCenter({
      lat,
      lng,
    });
  };

  const handlingMapDragging = (lat, long) => {
    handleMarkerDragging(lat, long);
  };

  const handleMapRef = position => {
    const mapCenter = position?.map?.center;
    if (
      mapCenter &&
      typeof mapCenter.lat === 'function' &&
      typeof mapCenter.lng === 'function'
    ) {
      const lat = mapCenter.lat();
      const lng = mapCenter.lng();
      handlingMapDragging(lat, lng);
    } else {
      setCenter(prevCenter);
    }
  };

  const locateUser = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const {latitude, longitude} = position.coords;
          setCenter({lat: latitude, lng: longitude});
        },
        error => {
          showErrorToast(t('unable_to_fetch_location'));
        },
        {enableHighAccuracy: true},
      );
    } else {
      showErrorToast(t('geolocation_not_supported'));
    }
  };

  return (
    <Modal
      isOpen={isDeliveryModalMapOpen}
      size="2xl"
      onClose={onClosingDeliveryMapModal}
      className="mx-[3rem] my-auto h-[33rem] w-[19.625rem] p-4 sm:h-[60dvh] sm:w-full"
      classNames={{
        closeButton: 'hidden',
        wrapper: 'items-start sm:items-center',
      }}>
      <ModalContent>
        <ModalHeader className="mb-4 flex items-center justify-between !p-0 text-center font-main text-[14px] font-medium text-persianIndigo sm:text-[16px] xl:text-[20px]">
          <span
            className={`${locale === 'en' ? 'sm:ml-[3.75rem]' : 'sm:mr-[3.75rem]'} w-full text-center`}>
            {Number(shoppingMethodId) === 1
              ? t('select_the_delivery_destination')
              : t('select_the_nearest_pickup_location')}
          </span>

          <CustomButton
            btnType="tertiary"
            value={'X'}
            btnStyles="text-[22px] text-persianIndigo !hover:!bg-none !p-0 hidden sm:block"
            onClickHandling={onClosingDeliveryMapModal}
          />
        </ModalHeader>
        <ModalBody className="h-full !p-0">
          <APIProvider
            apiKey={`${process.env.NEXT_PUBLIC_REACT_APP_MAPS_API_KEY}`}>
            <Map
              mapId="DEMO_MAP_ID"
              className="deliveryMap h-[90%] w-full"
              scaleControl={false}
              disableDefaultUI={true}
              zoomControl={false}
              zoom={zoom}
              onDragend={handleMapRef}
              center={center}>
              <MapControl position={ControlPosition.TOP_RIGHT}>
                <div className="relative w-full">
                  <FaSearch className="absolute left-[10px] top-[50%] z-[1] translate-y-[-50%] text-[#aaa]" />
                  <GooglePlacesAutocomplete
                    apiKey={`${process.env.NEXT_PUBLIC_REACT_APP_MAPS_API_KEY}`}
                    selectProps={{
                      isClearable: false,
                      isSearchable: true,
                      value: place,
                      onChange: handleChange,
                      placeholder: t('search'),
                      noOptionsMessage: () => t('no_address_found'),
                      loadingMessage: () => `${t('loading')}...`,
                      styles: {
                        container: baseStyles => ({
                          ...baseStyles,
                          '@media (min-width: 640px)': {
                            width: '12rem',
                          },
                        }),
                        control: baseStyles => ({
                          ...baseStyles,
                          borderColor: 'transparent',
                          borderRadius: '16px',
                          padding: '0 0 0 20px',
                          margin: '10px 10px 0 0',
                        }),
                        dropdownIndicator: baseStyles => ({
                          ...baseStyles,
                          opacity: '0',
                        }),
                        indicatorSeparator: baseStyles => ({
                          ...baseStyles,
                          opacity: '0',
                        }),
                        menu: baseStyles => ({
                          ...baseStyles,
                          borderRadius: '10px',
                          width: '16.4rem',
                          '@media (min-width: 640px)': {
                            width: '11.4rem',
                          },
                        }),
                      },
                    }}
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: ['sa'],
                      },
                    }}
                    debounce={1000}
                  />
                </div>
              </MapControl>
              {shoppingMethodId === 1 && (
                <>
                  <MapControl position={ControlPosition.CENTER}>
                    <FaMapMarkerAlt color="#814EAB" size={32} />
                  </MapControl>
                  <MapControl position={ControlPosition.BOTTOM_LEFT}>
                    <button
                      onClick={locateUser}
                      className="mb-2 flex items-center justify-center rounded-full bg-white shadow-md">
                      <BiCurrentLocation size={30} color="#814EAB" />
                    </button>
                  </MapControl>
                </>
              )}
              <div>
                <CustomZoomControls
                  onZoomIn={handleZoomIn}
                  onZoomOut={handleZoomOut}
                />
              </div>
              {shoppingMethodId !== 1 &&
                stores?.map((store, index) => (
                  <AdvancedMarker
                    key={index}
                    position={{
                      lat: Number(store.latitude),
                      lng: Number(store.longitude),
                    }}
                    onClick={() => handlePickStore(store)}>
                    <Pin
                      background={'#814EAB'}
                      borderColor={'#814EAB'}
                      glyphColor={'#382476'}
                    />
                  </AdvancedMarker>
                ))}
              {selectedStore && (
                <InfoWindow
                  position={{
                    lat: Number(selectedStore.latitude),
                    lng: Number(selectedStore.longitude),
                  }}
                  headerContent={selectedStore.name}
                  content={place?.label}
                  onCloseClick={() => setSelectedStore(null)}
                />
              )}
            </Map>
          </APIProvider>
          <Button
            isLoading={isGettingNearestStoreIsLoading}
            onPress={onHandlingPickingLocation}
            className="mx-auto mt-2 h-[2.5rem] w-full bg-royalPurple font-main text-white sm:mt-0 sm:w-[13.125rem]"
            radius="sm">
            {t('pick_location')}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DeliveryMapModal;
