'use client';

import {commonDisableCachingParameters} from '@/common';
import {getNearestStoreData} from '@/selectors';
import {
  useGetAllFiltersQuery,
  useGetAllProductsQuery,
  useLazyGetAllProductsQuery,
} from '@/services';
import debounce from 'lodash/debounce';
import {useTranslations} from 'next-intl';
import {useParams} from 'next/navigation';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {LuSettings2} from 'react-icons/lu';
import {useSelector} from 'react-redux';
import {useMediaQuery} from 'react-responsive';
import {Filters, MobileFilters, ProductList} from '../Category';
import {CustomButton} from '../common';

const SearchResults = ({searchTerm}) => {
  const t = useTranslations();
  const {locale} = useParams();
  const [filterValues, setFilterValues] = useState({});
  const [expandedFilters, setExpandedFilters] = useState(new Set());
  const [priceRange, setPriceRange] = useState([0, 1000]);
  const [highestPrice, setHighestPrice] = useState(1000);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);

  const onHandlingShowingFilters = () => {
    setIsFiltersVisible(!isFiltersVisible);
  };

  const isMobile = useMediaQuery({query: '(max-width: 640px)'});
  const store = useSelector(getNearestStoreData);

  const getAllProductsQueryParameters = {
    locale,
    'filter[search_term]': decodeURIComponent(searchTerm),
    include: 'images,taxons',
    per_page: 12,
    page: currentPage,
    'filter[taxon_name_not_eq]': 'AddOns,Gift Cards',
    'filter[price]': priceRange.join(','),
    'filter[branches]': store?.id,
    ...Object.entries(filterValues).reduce((acc, [key, values]) => {
      if (Array.isArray(values) && values.length > 0) {
        acc[`filter[options][${key}]`] = values;
      }
      return acc;
    }, {}),
  };

  const {
    data: allProducts,
    isLoading: isGettingProductsLoading,
    error: getProductsError,
    refetch: refetchAllProducts,
  } = useGetAllProductsQuery(
    getAllProductsQueryParameters,
    commonDisableCachingParameters,
  );

  const {
    data: allCategoryProducts,
    isLoading: isGettingAllCategoryProductsLoading,
    error: getAllCategoryProductsError,
  } = useGetAllProductsQuery(
    {sort_by: 'price-low-to-high', locale, 'filter[branches]': store?.id},
    commonDisableCachingParameters,
  );

  const [
    getAllCategoryProducts,
    {data: categoryProducts, isLoading: isGettingCategoryProducts},
  ] = useLazyGetAllProductsQuery();

  const {
    data: filters,
    isLoading: isGettingFiltersLoading,
    error: getFiltersError,
  } = useGetAllFiltersQuery(
    {include: 'option_values', locale},
    commonDisableCachingParameters,
  );

  const getFilterValues = filterId => {
    const optionType = filters?.data.find(option => option.id === filterId);
    if (!optionType) {
      return [];
    }
    const optionValueIds = optionType.relationships.option_values.data.map(
      value => value.id,
    );
    return filters?.included.filter(value => optionValueIds.includes(value.id));
  };

  const refetchAllCategoryProducts = useCallback(async () => {
    if (!isGettingAllCategoryProductsLoading) {
      await getAllCategoryProducts(
        {
          sort_by: 'price-low-to-high',
          locale,
          'filter[branches]': store?.id,
          page: allCategoryProducts?.meta?.total_pages,
        },
        commonDisableCachingParameters,
      );
    }
  }, [isGettingAllCategoryProductsLoading]);

  useEffect(() => {
    if (
      !isGettingAllCategoryProductsLoading &&
      allCategoryProducts?.data?.length > 0
    ) {
      refetchAllCategoryProducts();
      if (!isGettingCategoryProducts && categoryProducts?.data?.length > 0) {
        const maxPrice = Number(
          categoryProducts?.data[categoryProducts.data.length - 1].attributes
            .price,
        );
        setHighestPrice(maxPrice);
        setPriceRange([0, maxPrice]);
      }
    }
  }, [
    isGettingAllCategoryProductsLoading,
    allCategoryProducts,
    highestPrice,
    categoryProducts,
    isGettingCategoryProducts,
  ]);

  useEffect(() => {
    if (allProducts?.data?.length === 0 && currentPage > 1) {
      setCurrentPage(prev => prev - 1);
      refetchAllProducts();
    }
  }, [allProducts]);

  const handleFilterClick = filter => {
    const newExpandedFilters = new Set(expandedFilters);
    if (newExpandedFilters.has(filter)) {
      newExpandedFilters.delete(filter);
    } else {
      newExpandedFilters.add(filter);
    }
    setExpandedFilters(newExpandedFilters);
  };

  const handlePriceRangeChange = useMemo(() => {
    return debounce(range => {
      setPriceRange(range);
    }, 500);
  }, []);

  useEffect(() => {
    return () => {
      handlePriceRangeChange.cancel();
    };
  }, [handlePriceRangeChange]);

  return (
    <>
      <div className="pb-32">
        <div className="relative mx-[3rem] flex justify-end py-4 sm:gap-4 xl:mx-0 xl:px-16">
          {!isMobile && (
            <div className="flex w-full items-center justify-between">
              <h1 className="font-main text-[18px] font-normal text-persianIndigo">
                {t('home')} &gt; {t('searchh')} "
                {decodeURIComponent(searchTerm)}"
              </h1>
              <CustomButton
                iconOnly
                onClickHandling={onHandlingShowingFilters}
                value={<LuSettings2 className="text-[24px] text-thistle" />}
                btnStyles={`${isFiltersVisible ? 'border-[2px] border-solid border-persianIndigo' : ''} bg-white`}
              />
            </div>
          )}
        </div>
        {!isMobile && (
          <Filters
            filters={filters}
            handleFilterClick={handleFilterClick}
            setFilterValues={setFilterValues}
            t={t}
            isFiltersVisible={isFiltersVisible}
            expandedFilters={expandedFilters}
            getFilterValues={getFilterValues}
            onPriceRangeChange={handlePriceRangeChange}
            highestPrice={highestPrice}
          />
        )}

        {isMobile ? (
          <div className="flex-col">
            <h1 className="mx-[2.25rem] font-main text-[18px] font-normal text-persianIndigo">
              {t('home')} &gt; {t('searchh')} "{decodeURIComponent(searchTerm)}"
            </h1>
            <MobileFilters
              filters={filters}
              handleFilterClick={handleFilterClick}
              setFilterValues={setFilterValues}
              expandedFilters={expandedFilters}
              getFilterValues={getFilterValues}
              onPriceRangeChange={handlePriceRangeChange}
              highestPrice={highestPrice}
              t={t}
            />

            <div className="mx-4 mt-6">
              <ProductList
                products={allProducts}
                t={t}
                locale={locale}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                storeId={store?.id}
              />
            </div>
          </div>
        ) : (
          <div
            className={`${
              locale === 'en' ? 'sm:space-x-8' : 'sm:space-x-0'
            } flex flex-col sm:flex-row sm:!space-x-0 sm:px-16`}>
            <div className="flex sm:flex-col"></div>
            <ProductList
              products={allProducts}
              t={t}
              locale={locale}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              storeId={store?.id}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SearchResults;
