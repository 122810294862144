'use client';
import {Slider} from '@heroui/react';
import {useLocale} from 'next-intl';
import {useCallback, useEffect, useState} from 'react';
import {IoMdClose} from 'react-icons/io';

const MobileFilters = ({
  filters,
  handleFilterClick,
  expandedFilters,
  getFilterValues,
  setFilterValues,
  onPriceRangeChange,
  highestPrice,
  t,
}) => {
  const [localSelectedFilterOption, setLocalSelectedFilterOption] = useState(
    {},
  );
  const [priceRange, setPriceRange] = useState([0, highestPrice]);
  const locale = useLocale();

  const handleOptionClick = useCallback((filterName, option) => {
    setLocalSelectedFilterOption(prevState => {
      const existingOptions = prevState[filterName] || [];
      const isSelected = existingOptions.includes(option);
      const updatedOptions = isSelected
        ? existingOptions.filter(o => o !== option)
        : [...existingOptions, option];
      return {...prevState, [filterName]: updatedOptions};
    });
  }, []);

  useEffect(() => {
    if (setFilterValues) {
      setFilterValues(localSelectedFilterOption);
    }
  }, [localSelectedFilterOption, setFilterValues]);

  useEffect(() => {
    if (onPriceRangeChange) {
      onPriceRangeChange(priceRange);
    }
  }, [priceRange, onPriceRangeChange]);

  const handlePriceRangeChange = values => {
    setPriceRange(values);
  };

  return (
    <div className="mx-[2.25rem] mt-4">
      {filters ? (
        <>
          <h2 className="font-semiBold mb-2 font-main text-persianIndigo sm:pt-[28px] sm:text-[18px] xl:text-[1vw]">
            {t('filter')}
          </h2>
          <p className="mb-6 font-secondary text-[14px] text-persianIndigo">
            {t('choose_the_filters_you_would_like_to_see')}
          </p>
          <div>
            <div className="mb-6 flex flex-wrap items-center gap-2">
              {filters?.data
                .filter(item => item.attributes.name === 'price')
                .map((item, index) => (
                  <div key={index}>
                    <button
                      className={`${expandedFilters.has(item.id) ? 'justify-between border-2 border-persianIndigo' : 'justify-center border-2 border-thistle'} flex w-fit w-full items-center gap-4 rounded-full px-4 py-1 text-[12px]`}
                      onClick={() => handleFilterClick(item.id)}>
                      <span className="flex items-center justify-between font-secondary text-persianIndigo">
                        {item.attributes.presentation}
                      </span>
                      {expandedFilters.has(item.id) && (
                        <div className="relative top-[-1px] rounded-full border-1 border-persianIndigo">
                          <IoMdClose
                            size={12}
                            className="font-secondary font-bold text-persianIndigo"
                          />
                        </div>
                      )}
                    </button>
                  </div>
                ))}
            </div>

            {filters?.data
              .filter(item => item.attributes.name === 'price')
              .map((item, index) => (
                <div key={index}>
                  <div
                    className={`overflow-hidden transition-[max-height] duration-500 ease-in-out ${
                      expandedFilters.has(item.id) ? 'max-h-96' : 'max-h-0'
                    }`}>
                    <div className="flex flex-col text-persianIndigo">
                      {item.attributes.name === 'price' ? (
                        <div className="px-2">
                          <p className="font-secondary text-[14px] capitalize text-persianIndigo">
                            {item.attributes.presentation}
                          </p>
                          <Slider
                            label={t('select_range')}
                            step={1}
                            size="sm"
                            minValue={0}
                            maxValue={highestPrice}
                            defaultValue={priceRange}
                            formatOptions={{
                              style: 'currency',
                              currency: 'SAR',
                              numberingSystem:
                                locale === 'ar' ? 'arab' : 'hans',
                            }}
                            onChange={handlePriceRangeChange}
                            color="foreground"
                            classNames={{
                              track: 'bg-white',
                              filler: 'bg-thistle',
                              thumb: 'bg-thistle',
                              trackWrapper: 'mb-6',
                              label: 'sm:text-[10px] xl:text-[1vw]',
                              value: `sm:text-[10px] xl:text-[1vw]`,
                            }}
                          />
                        </div>
                      ) : (
                        <>
                          {getFilterValues(item.id).length === 0 ? (
                            <p className="mb-4 text-center font-secondary text-persianIndigo">
                              {t('no_filters_available')}
                            </p>
                          ) : (
                            <>
                              <p className="px-2 font-secondary text-[14px] capitalize text-persianIndigo">
                                {item.attributes.presentation}
                              </p>
                              <div className="mb-2 flex flex-wrap justify-center gap-2">
                                {getFilterValues(item.id).map(
                                  (option, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className={`${
                                          localSelectedFilterOption[
                                            item.attributes.name
                                          ]?.includes(option.attributes.name)
                                            ? 'border-black'
                                            : 'border-transparent'
                                        } cursor-pointer rounded-full border-1 p-2`}
                                        onClick={() =>
                                          handleOptionClick(
                                            item.attributes.name,
                                            option.attributes.name,
                                          )
                                        }>
                                        <div
                                          className={`${item.attributes.name === 'size' ? 'h-[1.2rem] w-[1.2rem] text-[0.8rem] font-bold' : 'h-[1.2rem] w-[1.2rem]'} flex justify-center rounded-full`}
                                          style={{
                                            backgroundColor:
                                              item.attributes.name ===
                                                'color' &&
                                              option.attributes.name,
                                            border:
                                              option.attributes.name ===
                                              '#ffffff'
                                                ? '1px solid #D3D3D3'
                                                : 'none',
                                          }}>
                                          {item.attributes.name === 'size' &&
                                            option.attributes.name}
                                        </div>
                                      </div>
                                    );
                                  },
                                )}
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </>
      ) : null}
      <hr className="border-0 border-t border-thistle" />
    </div>
  );
};

export default MobileFilters;
