'use client';

import {backFlower} from '@/assets';
import {useGetUserWithAddressesQuery, useLogoutMutation} from '@/services';
import {openLogIn, resetCarts} from '@/slices';
import {usePathname, useRouter} from '@/utils/navigation';
import {useLocale, useTranslations} from 'next-intl';
import Image from 'next/image';
import React, {useMemo, useState} from 'react';
import {CgMenuRight} from 'react-icons/cg';
import {
  IoIosArrowForward,
  IoIosArrowDown,
  IoIosArrowBack,
} from 'react-icons/io';
import {BiLogOut, BiLogIn} from 'react-icons/bi';
import {useDispatch, useSelector} from 'react-redux';
import {getToken} from '@/selectors';
import {commonDisableCachingParameters, showErrorToast} from '@/common';
import {FaRegUser} from 'react-icons/fa';

const SideBar = ({allCategories, isLoggedIn, togglingSideBar}) => {
  const [isOpen, setIsOpen] = useState(true);
  const pathName = usePathname();
  const [expandedCategories, setExpandedCategories] = useState({});
  const [languageExpanded, setLanguageExpanded] = useState(false);
  const dispatch = useDispatch();
  const router = useRouter();
  const t = useTranslations();
  const [logout, {isLoading: isLogoutLoading}] = useLogoutMutation();
  const token = useSelector(getToken);
  const locale = useLocale();
  const isCheckoutPage = useMemo(
    () => pathName.includes('checkout'),
    [pathName],
  );

  const toggleSidebar = () => {
    if (!isCheckoutPage) {
      setIsOpen(!isOpen);
      togglingSideBar(isOpen);
    }
  };

  const toggleExpanded = (category, index) => {
    const categoryName = category?.categoryName;
    const hasNoChildren = category?.children?.length === 0;
    const hasOnlyAllChild =
      category?.children?.length === 1 &&
      ['الكل', 'All'].includes(category?.children[0]?.name);
    if (['Events', 'فعاليات'].includes(categoryName)) {
      router.push('/events');
      toggleSidebar();
    } else if (hasNoChildren || hasOnlyAllChild) {
      router.push(`/category/${category?.permaLink}/${category?.id}`);
      toggleSidebar();
    } else {
      setExpandedCategories(prevState => ({
        ...prevState,
        [index]: !prevState[index],
      }));
    }
  };
  const onHandlingLogout = async () => {
    const response = await logout({
      access_token: token,
    });
    if (!response.error) {
      router.push('/');
      dispatch(resetCarts());
    } else {
      showErrorToast(response.error);
    }
  };

  const toggleLanguageDropdown = () => {
    setLanguageExpanded(!languageExpanded);
  };

  const handleLanguageChange = newLocale => {
    router.replace(pathName, {locale: newLocale});
  };
  const onHandlingNavigation = path => {
    router.push(path);
    toggleSidebar();
  };

  const onOpeningLogIn = () => {
    dispatch(openLogIn());
  };

  const handleProfile = () => {
    token ? router.push(`/profile`) : dispatch(openLogIn());
    toggleSidebar();
  };

  const {
    data: userData,
    isLoading: isGettingUserAddressLoading,
    error: getUserWithAddressesError,
  } = useGetUserWithAddressesQuery(
    {locale},
    {...commonDisableCachingParameters, skip: !token},
  );

  return (
    <>
      <CgMenuRight
        className={`${!isOpen ? 'sideBarOpening' : ''} ltr relative z-[1000] cursor-pointer text-[1.5rem] text-persianIndigo`}
        onClick={toggleSidebar}
      />
      {!isOpen && (
        <div
          className="fixed inset-0 z-[998] h-[100vh] bg-lightGray bg-opacity-70 backdrop-blur-[1px] backdrop-filter transition-opacity duration-300 ease-in-out"
          onClick={toggleSidebar}
        />
      )}
      <div
        dir={locale === 'ar' ? 'rtl' : 'ltr'}
        className={`${locale === 'ar' ? 'left-0' : 'right-0'} fixed top-0 z-[999] h-[100vh] w-[100vw] transform overflow-x-hidden overflow-y-scroll bg-white shadow-lg ${
          isOpen ? 'translate-x-[100%]' : 'translate-x-[30%]'
        } duration-50 transition-transform ease-in-out`}>
        <div className="relative mt-[2rem] h-full bg-transparent pt-[6.25rem]">
          <div
            className={`px-[1.75rem] ${locale === 'ar' ? 'text-right' : 'text-left'}`}>
            <Image
              src={backFlower}
              alt="Back Navbar Flower"
              height={310}
              width={210}
              className={`absolute ${locale === 'ar' ? 'right-[7.188rem]' : 'left-[7.188rem]'} top-[5rem] z-[-1] scale-150`}
            />
            {token && (
              <div
                className={`mb-8 flex items-center gap-2 ${locale === 'ar' ? 'mr-[8rem]' : 'mr-[5rem]'}`}
                onClick={handleProfile}>
                {userData?.user.profileImage ? (
                  <div className="h-[50px] w-[50px] cursor-pointer rounded-full">
                    <Image
                      src={`${userData?.user.profileImage}`}
                      width={100}
                      height={100}
                      className="h-full w-full rounded-full"
                      alt="user profile image"
                    />
                  </div>
                ) : (
                  <FaRegUser className="text-persianIndigo" size={20} />
                )}

                <h2
                  className={`cursor-pointer whitespace-normal break-words font-main text-persianIndigo ${locale === 'ar' ? 'text-right text-[16px]' : 'text-left text-[14px]'}`}
                  style={{
                    maxWidth: 'calc(100% - 60px)',
                  }}>
                  {t('hello')} {userData?.user?.firstName}
                </h2>
              </div>
            )}
          </div>
          <div
            className={`h-full px-[2.25rem] ${locale === 'ar' ? 'mr-[2rem] text-center' : 'text-left'}`}>
            <h2
              className={`font-main text-[1.25rem] font-bold text-persianIndigo ${locale === 'ar' ? '-mr-[5rem]' : ''}`}>
              {t('menu')}
            </h2>
            <ul
              className={`mt-4 min-h-[50%] ${locale === 'ar' ? 'mr-[5.75rem]' : ''}`}>
              {allCategories?.map((category, index) => (
                <li key={index} className="py-2">
                  <div
                    className={`flex cursor-pointer items-center gap-[15px] font-secondary text-persianIndigo`}
                    onClick={() => toggleExpanded(category, index)}>
                    {expandedCategories[index] ? (
                      <IoIosArrowDown />
                    ) : locale === 'ar' ? (
                      <IoIosArrowBack />
                    ) : (
                      <IoIosArrowForward />
                    )}
                    <button
                      className={`w-full text-[1rem] text-persianIndigo ${locale === 'ar' ? 'text-right' : 'text-left'}`}>
                      {category?.categoryName}
                    </button>
                  </div>

                  <ul
                    className={`text-gray-700 flex flex-col gap-4 overflow-y-scroll text-sm transition-[max-height] duration-300 ease-in-out ${
                      expandedCategories[index] ? 'max-h-[15rem]' : 'max-h-0'
                    }`}>
                    {category?.children?.map((child, childIndex) => (
                      <li
                        key={childIndex}
                        onClick={() =>
                          onHandlingNavigation(
                            `/category/${child.permaLink}/${child?.id}`,
                          )
                        }
                        className={`mt-2 cursor-pointer font-secondary text-[14px] text-persianIndigo ${locale === 'ar' ? 'pr-10 text-right' : 'pl-10'}`}>
                        {child.name}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
              <li className="py-2">
                <div
                  className={`flex cursor-pointer items-center gap-4 font-secondary text-persianIndigo`}
                  onClick={toggleLanguageDropdown}>
                  {languageExpanded ? (
                    <IoIosArrowDown />
                  ) : locale === 'ar' ? (
                    <IoIosArrowBack />
                  ) : (
                    <IoIosArrowForward />
                  )}
                  <button
                    className={`w-full font-secondary text-[16px] text-persianIndigo ${
                      locale === 'ar' ? 'text-right' : 'text-left'
                    }`}>
                    {t('language')}
                  </button>
                </div>

                <ul
                  className={`text-gray-700 flex flex-col gap-4 overflow-hidden ${
                    locale === 'ar' ? 'pr-10 text-right' : 'pl-10'
                  } text-sm transition-[max-height] duration-300 ease-in-out ${
                    languageExpanded ? 'max-h-40' : 'max-h-0'
                  }`}>
                  <li
                    className="mt-2 cursor-pointer font-secondary text-[14px] text-persianIndigo"
                    onClick={() => handleLanguageChange('en')}>
                    English
                  </li>
                  <li
                    className="cursor-pointer font-secondary text-[14px] text-persianIndigo"
                    onClick={() => handleLanguageChange('ar')}>
                    العربية
                  </li>
                </ul>
              </li>
            </ul>
            <h2
              className={`cursor-pointer ${locale === 'ar' ? 'mr-[5.75rem] pl-[30px] text-right' : 'text-left'} font-main text-[16px] text-persianIndigo`}
              onClick={isLoggedIn ? onHandlingLogout : onOpeningLogIn}>
              {isLoggedIn ? (
                locale === 'ar' ? (
                  <div className="flex items-center gap-2">
                    <span>{t('logout')}</span>
                    <BiLogOut className="text-[20px]" />
                  </div>
                ) : (
                  <div className="flex items-center gap-2">
                    <BiLogOut className="text-[20px]" />
                    <span>{t('logout')}</span>
                  </div>
                )
              ) : (
                <div className="flex items-center gap-2">
                  <BiLogIn className="text-[20px]" />
                  <span>{t('login')}</span>
                </div>
              )}
            </h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
