'use client';

import React, {useState, useEffect, useRef, useCallback, useMemo} from 'react';
import {
  Navbar as NavigationBar,
  NavbarMenuItem,
  NavbarMenu,
  Link,
} from '@heroui/react';
import {motion, useAnimation} from 'framer-motion';
import {useLocale, useTranslations} from 'next-intl';
import {SearchBar, UpperHeader} from '.';
import {
  useGetAllCategoriesWithTheirChildrenQuery,
  useGetNearestStoreQuery,
} from '@/services';
import {useDispatch, useSelector} from 'react-redux';
import {usePathname, useRouter} from '@/utils/navigation';
import {getCurrentUserCoords, getDeliveryMethodId, getToken} from '@/selectors';
import {useMediaQuery} from 'react-responsive';
import {UseGetOrCreateCart, UseHandleHiddenNavbarActions} from '@/hooks';
import dynamic from 'next/dynamic';

const MobileNavContent = dynamic(() => import('./MobileNavContent'), {
  ssr: false,
});
const DesktopNavContent = dynamic(() => import('./DesktopNavContent'), {
  ssr: false,
});
const CategorySubMenu = dynamic(() => import('./CategorySubMenu'), {
  ssr: false,
});

const Navbar = () => {
  const myNavbarRef = useRef(null);
  const t = useTranslations();
  const [isCategoryItemShown, setIsCategoryItemShown] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openKey, setOpenKey] = useState(null);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [profileName, setProfileName] = useState(null);
  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);

  const pathName = usePathname();
  const token = useSelector(getToken);
  const router = useRouter();
  const locale = useLocale();
  const dispatch = useDispatch();
  const userCoords = useSelector(getCurrentUserCoords);
  const deliveryMethodId = useSelector(getDeliveryMethodId);
  const isMobile = useMediaQuery({query: '(max-width: 640px)'});

  const isToShowProfileName = useMemo(
    () => profileName && token,
    [profileName, token],
  );

  const {data: nearestStore} = useGetNearestStoreQuery(
    {
      latitude: userCoords?.userLat || 24.7136,
      longitude: userCoords?.userLng || 46.6753,
      shopping_method_id: deliveryMethodId,
      locale,
    },
    {
      skip: !deliveryMethodId,
    },
  );

  const {isCheckoutPage, notToShowNavBar} =
    UseHandleHiddenNavbarActions(pathName);

  const {data: categoriesWithChildren} =
    useGetAllCategoriesWithTheirChildrenQuery({locale, per_page: 100});
  const [cart] = UseGetOrCreateCart();

  const controls = useAnimation();

  const handleShowingItems = useCallback(
    category => {
      if (isCheckoutPage) return;

      const {categoryName, children} = category;

      const isEvent = ['Events', 'فعاليات'].includes(categoryName);
      if (isEvent) {
        router.push('/events');
        setIsCategoryItemShown(false);
        setOpenKey(null);
      } else {
        const hasAll = ['الكل', 'All'].includes(children?.[0]?.name);
        const hasNoChildren = children?.length === 0;
        const hasOnlyAllChild = children?.length === 1 && hasAll;
        if (hasNoChildren || hasOnlyAllChild) {
          router.push(`/category/${category?.permaLink}/${category?.id}`);
          setOpenKey(null);
        } else {
          const isSameKey = categoryName === openKey?.categoryName;
          setIsCategoryItemShown(prevState => {
            const shouldShow = !prevState || !isSameKey;
            setOpenKey(shouldShow ? category : null);
            controls.start({transition: {duration: 0.5}});
            return shouldShow;
          });
        }
      }
    },
    [router, isCheckoutPage, openKey, controls],
  );

  const onHandlingNavigation = useCallback(
    path => {
      router.push(path);
      setIsCategoryItemShown(false);
    },
    [router],
  );

  const onHandlingPrefetching = useCallback(
    path => {
      router.prefetch(path);
    },
    [router],
  );

  const onTogglingSideBar = useCallback(sidebarState => {
    setIsSideBarOpen(sidebarState);
  }, []);

  useEffect(() => {
    if (isCheckoutPage) {
      setIsCategoryItemShown(false);
    }
  }, [isCheckoutPage]);

  return (
    <div className="sm:sticky sm:top-0 sm:z-[50] sm:bg-white">
      <UpperHeader navbarRef={myNavbarRef} nearestStore={nearestStore} />
      <div className="sticky top-0 z-[20] w-full sm:pe-[7.5vw] sm:ps-[7.25vw] sm:pt-4">
        <motion.div
          animate={controls}
          ref={myNavbarRef}
          className="top-0 z-[1000000] w-full bg-transparent sm:sticky sm:z-[10] sm:bg-white">
          <NavigationBar
            maxWidth="full"
            isBlurred={!isMobile}
            isMenuOpen={isMenuOpen}
            onMenuOpenChange={setIsMenuOpen}
            className="justify-between bg-transparent sm:bg-white">
            {isMobile ? (
              <MobileNavContent
                locale={locale}
                notToShowNavBar={notToShowNavBar}
                onHandlingNavigation={onHandlingNavigation}
                onTogglingSideBar={onTogglingSideBar}
                categoriesWithChildren={categoriesWithChildren}
                token={token}
                isCheckoutPage={isCheckoutPage}
                setIsSearchBarOpen={setIsSearchBarOpen}
                isSearchBarOpen={isSearchBarOpen}
                myNavbarRef={myNavbarRef}
                cart={cart}
              />
            ) : (
              <DesktopNavContent
                locale={locale}
                notToShowNavBar={notToShowNavBar}
                onHandlingNavigation={onHandlingNavigation}
                onHandlingPrefetching={onHandlingPrefetching}
                handleShowingItems={handleShowingItems}
                categoriesWithChildren={categoriesWithChildren}
                openKey={openKey}
                pathName={pathName}
                isCheckoutPage={isCheckoutPage}
                isSearchBarOpen={isSearchBarOpen}
                setIsSearchBarOpen={setIsSearchBarOpen}
                isToShowProfileName={isToShowProfileName}
                profileName={profileName}
                t={t}
                token={token}
                cart={cart}
                dispatch={dispatch}
                router={router}
                setProfileName={setProfileName}
                myNavbarRef={myNavbarRef}
              />
            )}

            <NavbarMenu>
              {['Profile'].map((item, index) => (
                <NavbarMenuItem key={`${item}-${index}`}>
                  <Link
                    className="w-full"
                    color={index === 0 ? 'danger' : 'foreground'}
                    href="#"
                    size="lg">
                    {item}
                  </Link>
                </NavbarMenuItem>
              ))}
            </NavbarMenu>
          </NavigationBar>
          {isMobile && notToShowNavBar && (
            <SearchBar isSearchBarOpen={isSearchBarOpen} />
          )}
          <div
            className={`${
              isSideBarOpen ? 'static' : 'relative z-[5000]'
            } flex justify-center px-6 sm:mt-[2.188rem] xl:px-[3.438rem]`}>
            {!isMobile && (
              <motion.div
                className="sm:grid sm:grid-cols-3 sm:gap-x-2 sm:gap-y-4 xl:flex xl:flex-wrap xl:gap-[2.5rem]"
                initial={{height: '0rem', opacity: 0}}
                animate={{
                  height: isCategoryItemShown ? 'auto' : '0',
                  opacity: isCategoryItemShown ? 1 : 0,
                }}
                transition={{duration: 0.5}}>
                <CategorySubMenu
                  isCategoryItemShown={isCategoryItemShown}
                  openKey={openKey}
                  onHandlingNavigation={onHandlingNavigation}
                  onHandlingPrefetching={onHandlingPrefetching}
                />
              </motion.div>
            )}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Navbar;
